import React, { FunctionComponent } from 'react';
import './SubscriptionText.scss';

import { Subscription } from '../../../interfaces';

interface SubscriptionTextProps {
  subscriptionType: Subscription;
}

const SubscriptionText: FunctionComponent<SubscriptionTextProps> = ({
  subscriptionType,
}) => {
  const {
    numOfSupportCalls,
    numOfPriorityContactGroups,
    numOfMinsPerSupportCall,
    numOfMinsPerCallToContact,
    priceOfAdditionalConciergeSupportPerMin,
    priceOfAdditionalCallToContactPerMin,
    name,
  } = subscriptionType;

  return name === 'Basic' ? (
    <div className="subscriptiontext__wrapper">
      <p className="subscription__text">
        • Automated, secure cloud backup of
        <br />
        unlimited number of your contacts
      </p>
      <p className="subscription__text">
        • Unlimited retrieval and restore of all <br />
        stored contacts, to any subscriber device
      </p>
      <p className="subscription__text">
        • Export/backup contact list in any <br />
        file-format
      </p>
      <p className="subscription__text">• No live concierge support</p>
    </div>
  ) : (
    <div className="subscriptiontext__wrapper">
      <p className="subscription__text__small__margin">
        • Unlimited contact storage & retrieval
      </p>
      <p className="subscription__text__small__margin">
        • {numOfSupportCalls} concierge support calls per year
      </p>

      <p className="subscription__text__small__margin">
        • Define {numOfPriorityContactGroups} contacts in a Priority Group{'\n'}
        for easy one-to-many messaging
      </p>

      <p className="subscription__text__small__margin">
        • {numOfMinsPerSupportCall} minutes per support call
      </p>

      <p className="subscription__text__small__margin">
        • {numOfMinsPerCallToContact} minutes per assisted call-to-contact
      </p>

      <p className="subscription__text__small__margin">
        • ${priceOfAdditionalConciergeSupportPerMin}/minute, additional
        concierge{'\n'}support time
      </p>
      <p className="subscription__text__small__margin">
        • ${priceOfAdditionalCallToContactPerMin}/minute, additional{'\n'}
        call-to-contact time
      </p>
    </div>
  );
};

export default SubscriptionText;
