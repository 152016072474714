import React, { FunctionComponent } from 'react';

interface SmallButtonProps {
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const SmallButton: FunctionComponent<SmallButtonProps> = ({
  text,
  onClick,
}) => {
  return (
    <button onClick={onClick} className="small_button">
      {text}
    </button>
  );
};

export default SmallButton;
