import React, { FunctionComponent } from 'react';
import './BackArrowButton.scss';

import backArrow from '../../../assets/backArrow.png';

interface BackArrowButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const BackArrowButton: FunctionComponent<BackArrowButtonProps> = ({
  onClick,
}) => {
  return (
    <button className="backArrow__button" onClick={onClick}>
      <img src={backArrow} alt="backArrow" className="backArrow" />
    </button>
  );
};

export default BackArrowButton;
