import React, { FunctionComponent, useContext, useState } from 'react';
import Modal from 'react-modal';

import './ContactModal.scss'; // staviti u main

import {
  ContactsContext,
  CustomerContext,
  GlobalStateContext,
} from '../../../contexts';

import {
  RegularButton,
  InfoLabel,
  AddressInfoLabel,
  ActivityIndicatorModal,
  NotesInfoLabel,
  CloseButton,
  SmallButton,
} from '../../index';
import EditContact from '../../contacts/EditContact/EditContact';

import generateInitials from '../../../util/functions/generateInitials';
import generateFullName from '../../../util/functions/generateFullName';

import {
  FormatedContact,
  MyUserAttributesFormatedContact,
} from '../../../interfaces';

interface ContactModalProps {
  showModal: boolean;
  modalContactId: number;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContactModal: FunctionComponent<ContactModalProps> = ({
  showModal,
  modalContactId,
  setShowModal,
}) => {
  const { setShowActivityIndicatorModal } = useContext(GlobalStateContext);
  const { editContact, contactsFromCloud } = useContext(ContactsContext);
  const {
    addToPiorityList,
    removeFromPiorityList,
    toogleIsDeletedForContactOnCloud,
    getAllContactsFromCloud,
  } = useContext(ContactsContext);

  const modalContact = contactsFromCloud.find(
    (contact) => contact.id === modalContactId
  ) as FormatedContact;

  const [showLoader, setShowLoader] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [editData, setEditData] = useState(
    {} as MyUserAttributesFormatedContact
  );
  const [companies, setCompanies] = useState<string[]>([] as string[]);
  const [profilePictureUri, setProfilePictureUri] = useState(
    modalContact?.myUserAttributes?.thumbnailUrl || ''
  );
  const [editContactData, setEditContactData] = useState({} as FormatedContact);
  const [numberError, setNumberError] = useState(false);

  const saveDataToCloud = () => {
    setShowActivityIndicatorModal(true);

    const contactToSend: FormatedContact = editContactData;
    contactToSend.myUserAttributes = editData;
    contactToSend.id = modalContact.id;
    modalContact.myCompanies = companies.map((company) => {
      const companyFormated = {
        name: company,
      };
      return companyFormated;
    });

    editContact(contactToSend, profilePictureUri)
      .then(getAllContactsFromCloud)
      .then(() => setShowActivityIndicatorModal(false))
      .catch((error) => {
        console.log(error);
        setShowActivityIndicatorModal(false);
      });
  };

  const closeModal = () => {
    setShowModal(false);
    setEditClicked(false);
    setNumberError(false);
  };

  const toggleEditMode = () => {
    if (numberError) {
      return;
    }
    if (editClicked) saveDataToCloud();

    setEditClicked(!editClicked);
  };

  const fullName =
    modalContact &&
    modalContact.myUserAttributes &&
    generateFullName(modalContact);

  // const initials = generateInitials(fullName);
  const initials =
    modalContact &&
    modalContact.myUserAttributes &&
    generateInitials(
      modalContact.myUserAttributes.firstName,
      modalContact.myUserAttributes.lastName
    );

  const phones =
    modalContact &&
    modalContact.myUserAttributes &&
    modalContact.myUserAttributes.phones.length > 0
      ? modalContact.myUserAttributes.phones
      : [];

  const emails =
    modalContact &&
    modalContact.myUserAttributes &&
    modalContact.myUserAttributes.emails.length > 0
      ? modalContact.myUserAttributes.emails
      : [];

  const contactAddress =
    modalContact && modalContact?.myUserAttributes?.myAddresses
      ? modalContact?.myUserAttributes?.myAddresses[0]
      : { street: null, state: null, city: null, zip: null, country: null };

  const street = contactAddress?.street || null;
  const state = contactAddress?.state || null;
  const city = contactAddress?.city || null;
  const zip = contactAddress?.zip || null;
  const country = contactAddress?.country || null;
  const address = { street, city, state, zip, country };
  const notes = (modalContact && modalContact.notes) || '';
  const compaines = modalContact?.myCompanies?.length
    ? modalContact.myCompanies
    : [];

  // modalContact && modalContact.myCompanies && modalContact.myCompanies.length
  //   ? modalContact.myCompanies
  //   : [];

  const renderCompaines = () => {
    return (
      compaines.length > 0 &&
      compaines
        .filter((company) => company.name)
        .map((company, index) => (
          <InfoLabel
            index={index}
            key={index}
            value={company.name}
            label="Company"
          />
        ))
    );
  };

  const renderEmails = () => {
    if (emails.length) {
      return emails.map((email, index) => (
        <InfoLabel
          index={index}
          key={index}
          value={email.value}
          label="Email"
        />
      ));
    }
  };

  const renderPhones = () =>
    phones.length > 0
      ? phones.map((phone, index) => (
          <InfoLabel
            index={index}
            key={index}
            value={phone.number}
            label="Phone"
          />
        ))
      : null;

  const renderNotes = () => notes && <NotesInfoLabel value={notes} />;

  const priorityId = modalContact?.priorityListId || null;
  const priorityContactsButtonText = modalContact?.priorityListId
    ? 'Remove from Priority Contacts'
    : 'Add to Priority Contacts';

  const handlePriority = () => {
    setShowLoader(true);

    if (priorityId) {
      return removeFromPiorityList([modalContact])
        .then(getAllContactsFromCloud)
        .then(() => setShowLoader(false))
        .then(() => setShowModal(false));
    }

    return addToPiorityList([modalContact])
      .then(getAllContactsFromCloud)
      .then(() => setShowLoader(false))
      .then(() => setShowModal(false));
  };

  const deleteContact = () => {
    setShowLoader(true);

    toogleIsDeletedForContactOnCloud(modalContact.id)
      .then(getAllContactsFromCloud)
      .then(() => setShowLoader(false))
      .then(() => setShowModal(false));
  };

  return (
      <Modal
        isOpen={showModal}
        ariaHideApp={false}
        className="contact__modal"
        overlayClassName="contact__modal__overlay"
      >
        <div className="card_modal__header">
          {modalContact?.isDeleted && <div className="not_deleted__wrapper" />}
          {!modalContact?.isDeleted && (
            <div className="small_button__wrapper">
              <SmallButton
                text={editClicked ? 'Done' : 'Edit'}
                onClick={toggleEditMode}
              />
            </div>
          )}

          <div className="profile__picture">
            <p className="profile__picture__initials">{initials}</p>
          </div>
          <CloseButton onClick={closeModal} />
        </div>
        <p className="contact_modal__fullname_text">{fullName}</p>
        <div className="error_wrapper">
          {numberError && (
            <p className="error_text">Phone Numbers cannot contain letters</p>
          )}
        </div>
        {editClicked ? (
          <div className="scroller">
            <EditContact
              contact={modalContact}
              editData={editData}
              setEditData={setEditData}
              companies={companies}
              setCompanies={setCompanies}
              numberError={numberError}
              setNumberError={setNumberError}
              profilePictureUri={profilePictureUri}
              setProfilePictureUri={setProfilePictureUri}
              editContactData={editContactData}
              setEditContactData={setEditContactData}
            />
          </div>
        ) : (
          <div className="contact_modal_dispay">
            <div className="contact_modal__innner_wrapper">
              {renderPhones()}
              {renderEmails()}
              {renderNotes()}
              {renderCompaines()}
              <AddressInfoLabel value={address} />
            </div>
            <div className="buttons__wrapper">
              {!modalContact?.isDeleted && (
                <RegularButton
                  type="black"
                  text={priorityContactsButtonText}
                  onClick={handlePriority}
                />
              )}

              <div className="divider" />
              <RegularButton
                type="red"
                text={modalContact?.isDeleted ? 'Restore' : 'Delete'}
                onClick={deleteContact}
              />
            </div>
          </div>
        )}

        <ActivityIndicatorModal showActivityIndicatorModal={showLoader} />
      </Modal>    
  );
};

export default ContactModal;
