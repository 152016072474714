import React, { useState, useEffect, useContext } from 'react';
import './ContactsPage.scss';

import { ContactsContext, GlobalStateContext } from '../../../contexts';

import { Contact, ContactModal, MainHeader } from '../../../components';

import generateFullName from '../../../util/functions/generateFullName';

import { FormatedContact } from '../../../interfaces';

import { DashboardTextEnum } from '../../../util/enums';

const ContactsPage = () => {
  const { contactsFromCloud, getAllContactsFromCloud } =
    useContext(ContactsContext);
  const { setShowActivityIndicatorModal } = useContext(GlobalStateContext);

  const [searchData, setSearchData] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalContactId, setModalContactId] = useState<number>(0);

  const priorityContacts = contactsFromCloud.filter(
    (contact) => contact.priorityListId
  );

  const onContactClick = (contact: FormatedContact) => {
    setModalContactId(contact.id);
    setShowModal(true);
  };

  // const sortContactsAlphabetically = (contactsFromCloud: FormatedContact[]) => {
  //   return contactsFromCloud.sort((a, b) => {
  //     return a.myUserAttributes?.firstName !== b.myUserAttributes.firstName
  //       ? a.myUserAttributes?.firstName < b.myUserAttributes.firstName
  //         ? -1
  //         : 1
  //       : 0;
  //   });
  // };

  const sortContactsAlphabetically = (contactsFromCloud: FormatedContact[]) => {
    return contactsFromCloud.sort((a, b) => {
      const fullNameA = generateFullName(a).toLowerCase();
      const fullNameB = generateFullName(b).toLowerCase();

      if (fullNameA < fullNameB) {
        return -1;
      }
      if (fullNameA > fullNameB) {
        return 1;
      }

      return 0;
    });
  };

  const filterContactsByNameEmailPhone = (contacts: FormatedContact[]) => {
    return contacts.filter((contact) => {
      const fullName = generateFullName(contact);
      const emails = contact.myUserAttributes.emails;
      const phoneNumbers = contact.myUserAttributes.phones;

      const fullNameIncluded = fullName
        .toLowerCase()
        .includes(searchData.toLowerCase());
      const emailsIncluded = emails.some((email) => {
        return email.value.toLowerCase().includes(searchData.toLowerCase());
      });
      const phoneNumbersIncluded = phoneNumbers.some((phone) =>
        phone.number.includes(searchData.toLowerCase())
      );

      if (fullNameIncluded) {
        return fullNameIncluded;
      }

      if (emailsIncluded) {
        return emailsIncluded;
      }

      if (phoneNumbersIncluded) {
        return phoneNumbersIncluded;
      }
    });
  };

  const renderContacts = (contactsFromCloud: FormatedContact[]) => {
    const sortedContactsAlphabetically =
      sortContactsAlphabetically(contactsFromCloud);

    const filteredContactsByNameEmailPhone = filterContactsByNameEmailPhone(
      sortedContactsAlphabetically
    );

    return contactsFromCloud.length > 0
      ? filteredContactsByNameEmailPhone.map((contact, index) => {
          const fullName = generateFullName(contact);

          const email =
            contact.myUserAttributes.emails.length > 0
              ? contact.myUserAttributes.emails[0].value.trim()
              : '';

          return (
            <Contact
              firstName={contact.myUserAttributes.firstName}
              lastName={contact.myUserAttributes.lastName}
              fullName={fullName}
              key={index}
              email={email}
              isDeleted={contact.isDeleted}
              onClick={() => onContactClick(contact)}
            />
          );
        })
      : null;
  };

  const contactsHeadline = (
    contacts: FormatedContact[],
    priority?: boolean
  ) => {
    const priorityText = priority ? 'Priority ' : '';

    if (!contacts.length) return 'You do not have any contacts';

    if (contacts.length > 1)
      return `You have ${contacts.length} ${priorityText}contacts added`;

    return `You have 1 ${priorityText}contact added`;
  };

  const renderContactsContent = () => {
    if (!contactsFromCloud.length) {
      return (
        <p className="no_contacts__headline">
          To complete account setup and backup your contacts, please download
          and run the 725-contact mobile app on your mobile device. You can find
          the app by searching "725" on the App Store or Google Play store.
        </p>
      );
    }

    return (
      <div>
        <label className="input__wraper">
          <input
            placeholder="Search by Name / Phone number / E-mail"
            value={searchData}
            onChange={(e) => {
              setSearchData(e.target.value);
            }}
            className="contacts__search__input"
          />
        </label>
        <div className="contacts__content__wrapper">
          <div className="contacts__wrapper">
            <p className="contacts__headline">
              {contactsHeadline(contactsFromCloud)}
            </p>
            <div className="contacts__list scroller">
              {renderContacts(contactsFromCloud)}
            </div>
          </div>
          <div className="priority__contacts__wrapper">
            <p className="contacts__headline">
              {contactsHeadline(priorityContacts, true)}
            </p>
            <div className="priority__contacts__list scroller">
              {renderContacts(priorityContacts)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setShowActivityIndicatorModal(true);
    getAllContactsFromCloud()
      .then(() => setShowActivityIndicatorModal(false))
      .catch((error) => {
        console.log(error);
        setShowActivityIndicatorModal(false);
      });
  }, []);

  return (
    <div className="contacts_page">
      <MainHeader title={DashboardTextEnum.CONTACTS} />
      <div className="contacts_page__wrapper">
        {renderContactsContent()}
        <ContactModal
          showModal={showModal}
          setShowModal={setShowModal}
          modalContactId={modalContactId}
        />
      </div>
    </div>
  );
};

export default ContactsPage;
