import React, { FunctionComponent, useContext } from 'react';
import Modal from 'react-modal';

import { ActivityIndicatorModal } from '../../components';

import './RegistrationSecondPartTemplate.scss';

import { GlobalStateContext } from '../../contexts';

interface RegistrationSecondPartTemplateProps {
  children: React.ReactNode;
}

const RegistrationSecondPartTemplate: FunctionComponent<RegistrationSecondPartTemplateProps> =
  ({ children }) => {
    const { showActivityIndicatorModal } = useContext(GlobalStateContext);

    return (
      <div className="registrationsecond__page__wrapper">
        <div className="inner__wrapper">{children}</div>
        <ActivityIndicatorModal
          showActivityIndicatorModal={showActivityIndicatorModal}
        />
      </div>
    );
  };

export default RegistrationSecondPartTemplate;
