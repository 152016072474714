import React, {
  createContext,
  useState,
  FunctionComponent,
  useContext,
  useEffect,
} from 'react';

import { AxiosContext } from './AxiosContext';

import {
  Subscription,
  SelectedSubscription,
  FormatedSubscription,
  PassphraseAndHint,
  SelectedSubscriptionMain,
} from '../interfaces';

interface SubscriptionContextProviderProps {
  children: React.ReactNode;
}

interface SubscriptionContextState {
  subscriptions: Subscription[];
  getAllSubscriptions: () => {};
  createCustomerSubscription: (
    token: string,
    isAutoRenew: boolean
  ) => Promise<number | void>;
  createSecurityQuestion: (
    passphraseAndHint: PassphraseAndHint
  ) => Promise<void>;
  formatSubscription: ({ id, isMonthly }: SelectedSubscription) => void;
  upgradeCustomerSubscription: (
    subscription: SelectedSubscriptionMain
  ) => Promise<void>;
  toggleAutoRenew: () => Promise<void>;
}

const SubscriptionContext = createContext({} as SubscriptionContextState);

const SubscriptionContextProvider: FunctionComponent<SubscriptionContextProviderProps> =
  (props) => {
    const [subscriptions, setSubscriptions] = useState<Subscription[]>(
      [] as Subscription[]
    );
    const [formatedSubscription, setFormatedSubscription] =
      useState<FormatedSubscription>({} as FormatedSubscription);

    const { axiosInstance } = useContext(AxiosContext);

    const getAllSubscriptions = async () => {
      return axiosInstance
        .get('/subscription/getAll')
        .then((response) => {
          console.log('SUPSKRIP{CIJE: ', response.data);
          setSubscriptions(response.data);
        })
        .catch((error) =>
          console.log('Error in getting subscriptions: ', error)
        );
    };

    const formatSubscription = ({ id, isMonthly }: SelectedSubscription) => {
      const paymentType = isMonthly ? 1 : 2;
      const subscriptionId = id;
      setFormatedSubscription({ subscriptionId, paymentType });
    };

    const createCustomerSubscription = async (
      token: string,
      isAutoRenew: boolean
    ) => {
      return axiosInstance
        .post('/subscription/createCustomerSubscription', {
          ...formatedSubscription,
          card: token,
          autoRenew: isAutoRenew,
        })
        .then((response) => {
          console.log(response.data);

          return response.status;
        })
        .catch((error) => console.log(error));
    };

    const createSecurityQuestion = async (
      passphraseAndHint: PassphraseAndHint
    ) => {
      return axiosInstance
        .post('/securityQuestion/create', passphraseAndHint)
        .then((response) => console.log(response.data))
        .catch((error) =>
          console.log('PASSPHRASE ERROR: ', error.response.data)
        );
    };

    const upgradeCustomerSubscription = async (
      subscription: SelectedSubscriptionMain
    ) => {
      const paymentType = subscription.monthly ? 1 : 2;
      const subscriptionId = subscription.id;

      return axiosInstance
        .put('/subscription/upgradeCustomerSubscription', {
          paymentType,
          subscriptionId,
        })
        .then((response) => console.log(response.data))
        .catch((error) =>
          console.log('UPDATING SUBSCRIPTION ERROR: ', error.response.data)
        );
    };

    const toggleAutoRenew = async () => {
      return axiosInstance
        .put('/subscription/toggleAutoRenew')
        .then((response) => console.log('TOGGLE AUTO RENEW:', response.data))
        .catch((error) =>
          console.log('CHANGING AUTORENEW ERROR: ', error.response.data)
        );
    };

    useEffect(() => {
      getAllSubscriptions();
    }, []);

    const providerValue = {
      subscriptions,
      getAllSubscriptions,
      createCustomerSubscription,
      createSecurityQuestion,
      formatSubscription,
      upgradeCustomerSubscription,
      toggleAutoRenew,
    };

    return (
      <SubscriptionContext.Provider value={providerValue}>
        {props.children}
      </SubscriptionContext.Provider>
    );
  };

export { SubscriptionContext, SubscriptionContextProvider };
