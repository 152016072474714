import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { CustomerContext, GlobalStateContext } from '../../../contexts';

import { AuthInput, RegularButton, BackArrowButton } from '../../../components';

import { RegistrationLinkEnum } from '../../../util/enums';

type Inputs = 'password' | 'repeatPassword' | 'hash';

const ResetPasswordPage3 = () => {
  const { resetPassword } = useContext(CustomerContext);
  const { setShowActivityIndicatorModal } = useContext(GlobalStateContext);

  const [isHashErrorVisible, setIsHashErrorVisible] = useState(false);
  const [isPasswordErrorVisible, setIsPasswordErrorVisible] = useState(false);
  const [isRepeatPasswordErrorVisible, setIsRepeatPasswordErrorVisible] =
    useState(false);
  const [recoverPasswordInputs, setRecoverPasswordInputs] = useState({
    password: '',
    repeatPassword: '',
    hash: '',
  });

  const regexPassword =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/; // Minimum eight characters, at least one letter and one number and one speical caracheter

  const validatePassword = () => {
    if (!recoverPasswordInputs.password) {
      setIsPasswordErrorVisible(true);
    } else if (
      regexPassword.test(String(recoverPasswordInputs.password)) === false &&
      recoverPasswordInputs.password
    ) {
      setIsPasswordErrorVisible(true);
    } else if (
      regexPassword.test(String(recoverPasswordInputs.password)) === true
    ) {
      setIsPasswordErrorVisible(false);
    }
  };

  const validateRepeatPassword = () => {
    if (recoverPasswordInputs.password !== recoverPasswordInputs.repeatPassword)
      setIsRepeatPasswordErrorVisible(true);
  };

  const validateHash = () => {
    if (!recoverPasswordInputs.hash) setIsHashErrorVisible(true);
  };

  const history = useHistory();

  const navigateBackwards = () => {
    history.goBack();
  };

  const navigateToTheNextPage = () => {
    history.push(RegistrationLinkEnum.LOGIN);
  };

  const handleInputs = (
    filedName: Inputs,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRecoverPasswordInputs({
      ...recoverPasswordInputs,
      [filedName]: event.target.value,
    });
  };

  const handleRecoverPasswordButton = () => {
    // if (!recoverPasswordInputs.hash) setIsHashErrorVisible(true);
    // if (!recoverPasswordInputs.password) setIsPasswordErrorVisible(true);
    // if (!recoverPasswordInputs.repeatPassword)
    //   setIsRepeatPasswordErrorVisible(true);

    // if (
    //   !isHashErrorVisible &&
    //   !isPasswordErrorVisible &&
    //   !isRepeatPasswordErrorVisible
    // ) {
    setShowActivityIndicatorModal(true);
    resetPassword(recoverPasswordInputs).then((response) => {
      setShowActivityIndicatorModal(false);
      console.log(response);
      navigateToTheNextPage();
    });
    // }
  };

  return (
    <div className="reset__password__page__wrapper3">
      <BackArrowButton onClick={navigateBackwards} />
      <div className="inner__wrapper">
        <p className="reset__password__headline">Reset Password</p>
        <p className="reset__password__text">
          Please enter the code that was sent to
          <br /> your email.
        </p>

        <AuthInput
          value={recoverPasswordInputs.hash}
          onChange={(e) => handleInputs('hash', e)}
          onBlur={validateHash}
          placeholder="Code*"
          textColor="black"
          errorText="Please enter your code"
          isErrorVisible={isHashErrorVisible}
          onFocus={() => setIsHashErrorVisible(false)}
        />

        <div className="separator" />

        <AuthInput
          value={recoverPasswordInputs.password}
          onChange={(e) => handleInputs('password', e)}
          onBlur={validatePassword}
          placeholder="New password*"
          textColor="black"
          errorText="Please enter correct password"
          isErrorVisible={isPasswordErrorVisible}
          onFocus={() => setIsPasswordErrorVisible(false)}
        />

        <AuthInput
          value={recoverPasswordInputs.repeatPassword}
          onChange={(e) => handleInputs('repeatPassword', e)}
          onBlur={validateRepeatPassword}
          placeholder="Repeat new password*"
          textColor="black"
          errorText="Passwords do not match"
          isErrorVisible={isRepeatPasswordErrorVisible}
          onFocus={() => setIsRepeatPasswordErrorVisible(false)}
        />

        <div className="button__wrapper">
          <RegularButton
            text="Recover password"
            type="black"
            onClick={handleRecoverPasswordButton}
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage3;
