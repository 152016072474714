import React, { FunctionComponent, useState } from 'react';
import './Dashboard.scss';

import { Link } from 'react-router-dom';

import logo from '../../assets/logo.png';
import callIcon from '../../assets/callIcon.png';

import { DashboardLinkEnum, DashboardTextEnum } from '../../util/enums';
import CallModal from '../modals/CallModal/CallModal';

interface DashboardProps {
  clickHandler: React.Dispatch<React.SetStateAction<DashboardTextEnum>>;
  selectedItem: string;
}

const Dashboard: FunctionComponent<DashboardProps> = ({
  clickHandler,
  selectedItem,
}) => {
  const [showCallingModal, setShowCallingModal] = useState(false);

  const renderDashboardMenuItems = () => {
    return Object.keys(DashboardTextEnum).map((key, index) => {
      const value = DashboardTextEnum[key as keyof typeof DashboardTextEnum];

      let className = 'link__container';

      if (selectedItem === value) {
        className = 'link__container__selected';
      }

      if (value === 'My Profile / Credit Cards') {
        className = 'link__container__not__visible';
      }

      if (value === DashboardTextEnum.CALL725CONTACT) {
        return (
          <div
            className={className}
            key={index}
            onClick={() => setShowCallingModal(true)}
          >
            <p>{value}</p>
            <img src={callIcon} alt="call_icon" className="call_icon" />
          </div>
        );
      }

      return (
        <Link
          to={DashboardLinkEnum[key as keyof typeof DashboardLinkEnum]}
          className={className}
          onClick={() => clickHandler(value)}
          key={index}
        >
          {value}
        </Link>
      );
    });
  };

  return (
    <div className="dashboard">
      <div className="dashboard_logo">
        <img src={logo} alt="725" className="dashboard_logo--image" />
      </div>
      <div className="links__wrapper">{renderDashboardMenuItems()}</div>
      <CallModal
        showModal={showCallingModal}
        setShowModal={setShowCallingModal}
      />
    </div>
  );
};

export default Dashboard;
