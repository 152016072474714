import React, { FunctionComponent, useEffect } from 'react';

import { DropdownButton } from '../..';

import { TickedPeriod } from '../../../util/enums';

interface DropDownTicketProps {
  headline: string;
  isDropdownOpened: boolean;
  statusLabels: string[];
  dropDownHandler: () => void;
  setStatusLabel: React.Dispatch<React.SetStateAction<string>>;
}

const DropDownTicket: FunctionComponent<DropDownTicketProps> = ({
  headline,
  isDropdownOpened,
  statusLabels,
  dropDownHandler,
  setStatusLabel,
}) => {
  const dropDownButtonHandler = (label: string) => {
    setStatusLabel(label);
    dropDownHandler();
  };

  return (
    <div className="other_search_wrapper">
      <p className="other_search_text">{headline}</p>
      <DropdownButton arrowHandler={dropDownHandler} />
      <div className="dropdown_content_wrapper">
        {statusLabels.map((label, index) => {
          let dropDownPickerClass = 'dropdown_picker';

          if (statusLabels.length === index + 1) {
            dropDownPickerClass = 'dropdown_picker_last_item';
          }

          return (
            <button
              key={index}
              className={dropDownPickerClass}
              onClick={() => {
                dropDownButtonHandler(label);
              }}
            >
              <p className="dropdown_text">{label}</p>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default DropDownTicket;
