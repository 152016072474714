import React, { FunctionComponent } from 'react';
import './SubscriptionAuthCard.scss';

import { Subscription } from '../../../interfaces';

import SubscriptionText from '../SubscriptionText/SubscriptionText';

interface SubscriptionAuthCardProps {
  subscriptionType: Subscription;
  isMonthly: boolean;
  chosenSubscriptionId: number;
  index: number;
  numberOfSubscriptions: number;
  setChosenSubscriptionId: React.Dispatch<React.SetStateAction<number>>;
}

const SubscriptionAuthCard: FunctionComponent<SubscriptionAuthCardProps> = ({
  subscriptionType,
  isMonthly,
  chosenSubscriptionId,
  index,
  numberOfSubscriptions,
  setChosenSubscriptionId,
}) => {
  const price = isMonthly
    ? subscriptionType.monthlyPrice
    : subscriptionType.yearlyPrice;

  const priceFlag = isMonthly ? 'Month' : 'Year';

  const getWrapperClassName = () => {
    if (numberOfSubscriptions === 3) {
      if (index === 0 && chosenSubscriptionId === subscriptionType.id) {
        return 'subscription__authcard__wrapper__with__background1';
      }

      if (index === 1 && chosenSubscriptionId === subscriptionType.id) {
        return 'subscription__authcard__wrapper__with__background3';
      }

      if (index === 2 && chosenSubscriptionId === subscriptionType.id) {
        return 'subscription__authcard__wrapper__with__background5';
      }
    }
  
    if (numberOfSubscriptions === 4) {
      if (index === 0 && chosenSubscriptionId === subscriptionType.id) {
        return 'subscription__authcard__wrapper__with__background1';
      }

      if (index === 1 && chosenSubscriptionId === subscriptionType.id) {
        return 'subscription__authcard__wrapper__with__background2';
      }

      if (index === 2 && chosenSubscriptionId === subscriptionType.id) {
        return 'subscription__authcard__wrapper__with__background3';
      }

      if (index === 3 && chosenSubscriptionId === subscriptionType.id) {
        return 'subscription__authcard__wrapper__with__background5';
      }
    }
  
    if (numberOfSubscriptions === 5) {
      if (index === 0 && chosenSubscriptionId === subscriptionType.id) {
        return 'subscription__authcard__wrapper__with__background1';
      }

      if (index === 1 && chosenSubscriptionId === subscriptionType.id) {
        return 'subscription__authcard__wrapper__with__background2';
      }

      if (index === 2 && chosenSubscriptionId === subscriptionType.id) {
        return 'subscription__authcard__wrapper__with__background3';
      }

      if (index === 3 && chosenSubscriptionId === subscriptionType.id) {
        return 'subscription__authcard__wrapper__with__background4';
      }
      
      if (index === 4 && chosenSubscriptionId === subscriptionType.id) {
        return 'subscription__authcard__wrapper__with__background5';
      }
    }

    return 'subscription__authcard__wrapper';
  }

  const handleSubscriptionClick = () => {
    setChosenSubscriptionId(subscriptionType.id);
  };

  return (
    <button className={getWrapperClassName()} onClick={handleSubscriptionClick}>
      <div className="subscription__card__inner__wrapper">
        <p className="subscription__type__text">{subscriptionType.name}</p>
        <div className="subscription__price__wrapper">
          <p className="subscription__price__text">${price}</p>
          <p className="subscription__price__period">/ {priceFlag}</p>
        </div>
        <SubscriptionText subscriptionType={subscriptionType} />
        <div className="discount__text__wrapper">
          {(subscriptionType.name === 'Plus' ||
            subscriptionType.name === 'Premium') && (
            <p className="discount__text">save 25%</p>
          )}
        </div>
      </div>
    </button>
  );
};

export default SubscriptionAuthCard;
