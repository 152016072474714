import React, { FunctionComponent } from 'react';

interface NotesInfoLabelProps {
  value: string;
}

const NotesInfoLabel: FunctionComponent<NotesInfoLabelProps> = ({ value }) => {
  return (
    <div className="contact__info__label__wrapper">
      <div className="contact__info__label__inner_wrapper">
        <p className="label__name__text">Notes</p>
        <p className="value_text">{value}</p>
      </div>
    </div>
  );
};

export default NotesInfoLabel;
