import React, { FunctionComponent } from 'react';
import './AddressInfoLabel.scss';

type AddressType = {
  street: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  country: string | null;
};

interface InfoLabelProps {
  value: AddressType;
}

const AddressInfoLabel: FunctionComponent<InfoLabelProps> = ({ value }) => {
  const isAddressVisible =
    value?.street || value?.city || value?.state || value?.zip || value?.country
      ? true
      : false;

  return (
    <div>
      {isAddressVisible && (
        <div className="address__info__label__wrapper">
          <div className="address__info__label__inner_wrapper">
            <p className="label__name__text">Home</p>
            {value.street !== null && (
              <p className="value_text">{value.street}</p>
            )}
            {value.city !== null && <p className="value_text">{value.city}</p>}
            {value.state !== null && (
              <p className="value_text">{value.state}</p>
            )}
            {value.zip !== null && <p className="value_text">{value.zip}</p>}
            {value.country !== null && (
              <p className="value_text">{value.country}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddressInfoLabel;
