import React, { FunctionComponent } from 'react';
import { AuthenticationContextProvider } from './AuthenticationContext';
import { AxiosContextProvider } from './AxiosContext';
import { LoginContextProvider } from './LoginContext';
import { GlobalStateContextProvider } from './GlobalStateContext';
import { SubscriptionContextProvider } from './SubscriptionContext';
import { CustomerContextProvider } from './CustomerContext';
import { ContactsContextProvider } from './ContactsContext';
import { CreditCardContextProvider } from './CreditCardContext';
import {TwilioContextProvider} from "./TwilioContext";
import {TimerContextProvider } from "./TimerContext"; 
interface GlobalContextProps {
  children: React.ReactNode;
}

const GlobalContextProvider: FunctionComponent<GlobalContextProps> = ({
  children,
}) => {
  return (
    <AuthenticationContextProvider>
      <AxiosContextProvider>
        <SubscriptionContextProvider>
          <LoginContextProvider>
            <GlobalStateContextProvider>
              <CustomerContextProvider>
                <ContactsContextProvider>
                  <CreditCardContextProvider>
                    <TwilioContextProvider>
                      <TimerContextProvider>
                    {children}
                    </TimerContextProvider>
                    </TwilioContextProvider>
                  </CreditCardContextProvider>
                </ContactsContextProvider>
              </CustomerContextProvider>
            </GlobalStateContextProvider>
          </LoginContextProvider>
        </SubscriptionContextProvider>
      </AxiosContextProvider>
    </AuthenticationContextProvider>
  );
};

export default GlobalContextProvider;
