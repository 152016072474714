import React, { FC, useContext, useState } from 'react'
import { useHistory } from 'react-router';
import { AuthInput, RegularButton } from '../../../../../components';
import { AuthenticationContext, GlobalStateContext, LoginContext } from '../../../../../contexts';
import { RegistrationLinkEnum } from '../../../../../util/enums';

interface LoginPassphraseComponentProps {
    userId: number;
    securityQuestionId: number;
    hint: string;
}

const LoginPassphraseComponent: FC<LoginPassphraseComponentProps> = (props) => {
    const { userId, securityQuestionId, hint } = props;

    const { setShowActivityIndicatorModal } = useContext(GlobalStateContext);
    const { twoStepLogin } = useContext(LoginContext);
    const { saveUserToken } = useContext(AuthenticationContext);

    const initialData = {
        passphrase: '',
        userId: userId,
        securityQuestionId: securityQuestionId
    };
    
    const [inputData, setInputData] = useState(initialData);
    const [isHintShown, setIsHintShown] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);

    const history = useHistory();

    const navigateToForgotPassword = () => {
        history.push(RegistrationLinkEnum.RESETPASSWORD);
    };

    const inputHandler = (
        fieldName: string,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setInputData({
            ...inputData,
            [fieldName]: event.target.value,
        });
    };

    const handleLoginTwoStep = () => {
        setShowActivityIndicatorModal(true);

        twoStepLogin(inputData).then((response) => {
            console.log(response.status);

            if (response.status === 404 || response.status === 401) {
                setIsErrorVisible(true);
                setShowActivityIndicatorModal(false);

                return;
            }
            
            saveUserToken(response.data.token);
        });
    };

    return (
        <>
            <div className="passphrase__top_divider"></div>
            {
                isHintShown
                ? <p className="hint__text">{hint}</p>
                : <RegularButton type="white" text="Show hint" onClick={() => setIsHintShown(true)} />
            }
            <div className="passphrase__divider"></div>
            <AuthInput
                value={inputData.passphrase}
                onChange={(e) => inputHandler('passphrase', e)}
                placeholder="Passphrase"
                onBlur={() => {}}
                textColor="black"
                errorText="The passphrase is not correct"
                isErrorVisible={isErrorVisible}
                onFocus={() => setIsErrorVisible(false)}
            />
            <button
                className="forgot__password__button"
                onClick={navigateToForgotPassword}
            >
                <p className="forgot__password__text">Forgot your password?</p>
            </button>
            <RegularButton type="white" text="Log in" onClick={handleLoginTwoStep} />
        </>
    )
}

export default LoginPassphraseComponent
