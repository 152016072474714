import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  SubscriptionContext,
  GlobalStateContext,
  CustomerContext,
} from '../../../contexts';

import {
  AuthInput,
  RegularButton,
  BackArrowButton,
  ActivityIndicatorModal,
} from '../../../components';

import { RegistrationLinkEnum } from '../../../util/enums';

type PassphraseInputs = 'passPhrase' | 'hint';

const SignUpPassphrasePage = () => {
  const { createSecurityQuestion } = useContext(SubscriptionContext);
  const { setShowActivityIndicatorModal } = useContext(GlobalStateContext);
  const { isLoading } = useContext(CustomerContext);

  const [passPhraseInputData, setPassPhraseInputData] = useState({
    passPhrase: '',
    hint: '',
  });
  const [isPassPhraseErrorVisible, setIsPassPhraseErrorVisible] =
    useState(false);
  const [isHintErrorVisible, setIsHintErrorVisible] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const history = useHistory();

  const navigateBackwards = () => {
    history.goBack();
  };

  const navigateToChoosePlan = () => {
    history.push(RegistrationLinkEnum.SIGNUPCHOOSEPLAN);
  };

  const areInputsSame =
    passPhraseInputData.passPhrase === passPhraseInputData.hint;

  const handleError = () => {
    if (
      passPhraseInputData.passPhrase &&
      passPhraseInputData.hint &&
      areInputsSame
    ) {
      return setIsErrorVisible(true);
    }
    if (!passPhraseInputData.passPhrase) setIsPassPhraseErrorVisible(true);
    if (!passPhraseInputData.hint) setIsHintErrorVisible(true);
  };

  const onInputFocuse = () => {
    setIsErrorVisible(false);
    if (!passPhraseInputData.passPhrase) setIsPassPhraseErrorVisible(false);
    if (!passPhraseInputData.hint) setIsHintErrorVisible(false);
  };

  const handleNextButton = () => {
    handleError();

    if (!passPhraseInputData.passPhrase || !passPhraseInputData.hint) return;
    if (!areInputsSame) {
      setShowActivityIndicatorModal(true);
      createSecurityQuestion(passPhraseInputData)
        .then(() => setShowActivityIndicatorModal(false))
        .then(navigateToChoosePlan);
    }
  };

  const handlePassphraseInputs = (
    fieldName: PassphraseInputs,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPassPhraseInputData({
      ...passPhraseInputData,
      [fieldName]: event.target.value,
    });
  };

  return (
    <div className="signup__passphrase__page__wrapper">
      <BackArrowButton onClick={navigateBackwards} />
      <div className="inner__wrapper">
        <p className="your__passphrase__text">Your Passphrase</p>
        <AuthInput
          value={passPhraseInputData.passPhrase}
          onChange={(e) => {
            handlePassphraseInputs('passPhrase', e);
          }}
          placeholder="Enter passphrase*"
          textColor="black"
          errorText="Please enter your Passphrase"
          isErrorVisible={isPassPhraseErrorVisible}
          onFocus={onInputFocuse}
        />
        <AuthInput
          value={passPhraseInputData.hint}
          onChange={(e) => {
            handlePassphraseInputs('hint', e);
          }}
          placeholder="Enter challenge hint question*"
          textColor="black"
          errorText="Please enter your Hint Message"
          isErrorVisible={isHintErrorVisible}
          onFocus={onInputFocuse}
        />
        <div className="error_message__wrapper">
          {isErrorVisible && (
            <p className="error_message_text">
              Passphrase and Hint cannot be the same
            </p>
          )}
        </div>

        <div className="button__wrapper">
          <RegularButton text="Next" type="black" onClick={handleNextButton} />
        </div>
      </div>
      <ActivityIndicatorModal showActivityIndicatorModal={isLoading} />
    </div>
  );
};

export default SignUpPassphrasePage;
