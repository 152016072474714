import { SubscriptionCustomer, SubscriptionDefault } from '../../interfaces';

import { SubscriptionTypesEnum } from '../enums/Subscription';

const isSubscriptionSelectionDisabled = (
  customerSubscription: SubscriptionCustomer,
  selectionSubscription: SubscriptionDefault,
  isMonthly: boolean
) => {
  if (customerSubscription) {
    if (
      selectionSubscription.yearlyPrice <
        customerSubscription.subscriptionAttributes.yearlyPrice ||
      selectionSubscription.monthlyPrice <
        customerSubscription.subscriptionAttributes.monthlyPrice
    ) {
      return true;
    }
    if (
      isMonthly &&
      customerSubscription.paymentType === SubscriptionTypesEnum.YEARLY
    ) {
      return true;
    }
    if (
      isMonthly &&
      customerSubscription.subscriptionAttributes.name === 'Basic'
    ) {
      return true;
    }
    return false;
  }
  return false;
};

export default isSubscriptionSelectionDisabled;
