import React from 'react';

import { ReactComponent as ReactLogo } from '../../../assets/appStoreSVG.svg';

import logo from '../../../assets/logo.png';
import iphonePicture from '../../../assets/iphonemockup.png';
import googlePlay from '../../../assets/googlePlay.png';

const RedirectPage = () => {
  return (
    <div className="redirect_page">
      <img src={logo} alt="logo" className="logo" />

      <div className="stores__wrapper">
        <img src={googlePlay} alt="logo" className="googlePlay" />
        <ReactLogo className="appStore" />
      </div>
    </div>
  );
};

export default RedirectPage;
