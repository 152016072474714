import React, { createContext, FC, useState } from 'react';

import { DashboardTextEnum } from '../util/enums';

interface IGlobalStateContext {
  showActivityIndicatorModal: boolean;
  setShowActivityIndicatorModal(state: boolean): void;
  selectedItem: DashboardTextEnum;
  setSelectedItem: React.Dispatch<React.SetStateAction<DashboardTextEnum>>;
}

const GlobalStateContext = createContext({} as IGlobalStateContext);

interface GlobalStateContextProps {
  children: React.ReactNode;
}

const GlobalStateContextProvider: FC<GlobalStateContextProps> = ({
  children,
}) => {
  const [showActivityIndicatorModal, setShowActivityIndicatorModal] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState<DashboardTextEnum>(
    DashboardTextEnum.CONTACTS
  );

  const providerValue = {
    showActivityIndicatorModal,
    setShowActivityIndicatorModal,
    selectedItem,
    setSelectedItem,
  };

  return (
    <GlobalStateContext.Provider value={providerValue}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export { GlobalStateContext, GlobalStateContextProvider };
