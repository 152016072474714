import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router';

import {
  SubscriptionContext,
  GlobalStateContext,
  CustomerContext,
} from '../../../contexts';

import {
  RegistrationHeader,
  FrequencySwitch,
  CreditCardModal,
  CreditCardFront,
  AddCreditCard,
} from '../../../components';

import googlePayLogo from '../../../assets/googlePayLogo.png';
import applePayLogo from '../../../assets/applePayLogo.png';

import { CreditCard } from '../../../interfaces';

const PaymentTypePage = () => {
  const { createCustomerSubscription } = useContext(SubscriptionContext);
  const { setShowActivityIndicatorModal } = useContext(GlobalStateContext);
  const { getCustomerDataFromToken } = useContext(CustomerContext);

  const [isOffClicked, setIsOffClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [creditCard, setCreditCard] = useState({} as CreditCard);
  const [error, setError] = useState("");

  const openModal = () => {
    setShowModal(true);
  };

  const history = useHistory();

  const handleNextButton = () => {
    setShowActivityIndicatorModal(true);
    createCustomerSubscription(creditCard.token, isOffClicked)
      .then((response) => {
        if (response === 200) {
          getCustomerDataFromToken().then(() => {
            setShowActivityIndicatorModal(false);
            setCreditCard({} as CreditCard);
            history.push('/');
          });
        } else {
          setShowActivityIndicatorModal(false);
          setError("There was an error with your card. Please try again.");
          setCreditCard({} as CreditCard);
        }
      })
      .catch((error) => {
        console.log(error);
        setCreditCard({} as CreditCard);
        setShowActivityIndicatorModal(false);
      });
  };

  const handleBackButton = () => {
    history.goBack();
  };

  return (
    <div className="payment__type__wrapper">
      <RegistrationHeader
        onClickBack={handleBackButton}
        onClickNext={handleNextButton}
        text="Payment Type"
        isButtonDisabled={!creditCard.token}
      />
      <div className="payment__type__inner__wrapper">
        <div className="frequency__switch__wrapper">
          <p className="frequency__switch__text">Auto re-new subscription</p>
          <FrequencySwitch
            textLeft="On"
            textRight="Off"
            isRightClicked={isOffClicked}
            setIsRightClicked={setIsOffClicked}
          />
        </div>

        <div className="apple__google__pay__wrapper">
          <button className="apple__button">
            <img
              src={applePayLogo}
              alt="applePayLogo"
              className="apple__logo__png"
            />
          </button>
          <button className="google__button">
            <img
              src={googlePayLogo}
              alt="googlePayLogo"
              className="google__logo__png"
            />
          </button>
        </div>
        {!creditCard.name ? (
          <AddCreditCard onClick={openModal} />
        ) : (
            <CreditCardFront
              name={creditCard.name}
              exp_month={creditCard.exp_month}
              exp_year={creditCard.exp_year}
              last4={creditCard.last4}
            />
        )}
        <div>
          <p className="error__text">{error}</p>
        </div>        
      </div>
      <CreditCardModal
        showModal={showModal}
        setShowModal={setShowModal}
        setCreditCard={setCreditCard}
      />      
    </div>
  );
};

export default PaymentTypePage;
