import React, { createContext, FC, useContext, useState } from 'react'

interface ITimerContext {
    totalCallTime: number;
    setTotalCallTime(state: number): void;
    setTotalCallTimer (state: ReturnType<typeof setInterval> | undefined): void;
    setIsTotalCallOn(state: boolean): void;
    isTotalCallOn: boolean;
    totalCallTimer: ReturnType<typeof setInterval> | undefined;
    startTimer(): void;
    stopTimer(): void;
    resetTimer(): void;
};

const TimerContext = createContext({} as ITimerContext);

interface TimerContextProps {
    children: React.ReactNode;
};

const TimerContextProvider: FC<TimerContextProps> = (props) => {
    const [totalCallTime, setTotalCallTime] = useState(0);
    const [isTotalCallOn, setIsTotalCallOn] = useState(false);
    const [totalCallTimer, setTotalCallTimer] = useState<ReturnType<typeof setInterval>>();
    
    const startTimer = () => {
        setIsTotalCallOn(true);
        setTotalCallTime(0);

        let seconds = 0;
        
        setTotalCallTimer(
            setInterval(
                () => {
                    seconds += 1;
                        setTotalCallTime(seconds);
                },
                1000
            )
        )
    }

    const stopTimer = () => {
        setIsTotalCallOn(false);
        if (totalCallTimer) {
            clearInterval(totalCallTimer);
        }
    }


    const resetTimer = () => {
        setTotalCallTime(0);
        setIsTotalCallOn(false);
        setTotalCallTimer(undefined);

    }

    const providerValue = {
        totalCallTime,
        setTotalCallTime,
        totalCallTimer,
        setIsTotalCallOn,
        isTotalCallOn,
        setTotalCallTimer,
        startTimer,
        stopTimer,
        resetTimer
    }

    return (
        <TimerContext.Provider value={providerValue}>
            {props.children}
        </TimerContext.Provider>
    )
}

export { TimerContext , TimerContextProvider}
