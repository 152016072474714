import React, { FunctionComponent } from 'react';

import generateInitials from '../../../util/functions/generateInitials';

interface ContactProps {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  isDeleted?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const Contact: FunctionComponent<ContactProps> = ({
  firstName,
  lastName,
  fullName,
  email,
  isDeleted,
  onClick,
}) => {
  // const initials = generateInitials(fullName);
  const initials = generateInitials(firstName, lastName);

  return (
    <button className="contact__page__wrapper" onClick={onClick}>
      <div className={!isDeleted ? 'contact__wrapper' : 'contact__deleted'}>
        <div className="contact__circle__wrapper">
          <p className="initials__text">{initials}</p>
        </div>
        <div className="contact__info">
          <p className="full__name__text">{fullName}</p>
          <p className="email__text">{email}</p>
        </div>
      </div>
    </button>
  );
};

export default Contact;
