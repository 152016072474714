import React, { FunctionComponent } from 'react';

interface RegularButtonProps {
  type: 'black' | 'white' | 'darkGrey' | 'greyWhenDisabled' | 'red';
  text: string;
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const RegularButton: FunctionComponent<RegularButtonProps> = ({
  type,
  text,
  disabled,
  onClick,
}) => {
  let className = 'black';

  if (type === 'black') {
    className = 'blackButton';
  } else if (type === 'white') {
    className = 'whiteButton';
  } else if (type === 'darkGrey') {
    className = 'darkGreyButton';
  } else if (type === 'red') {
    className = 'redButton';
  } else if (type === 'greyWhenDisabled') {
    className = disabled ? 'lightGreyButton' : 'blackButton';
  }

  return (
    <button disabled={disabled} onClick={onClick} className={className}>
      {text}
    </button>
  );
};
export default RegularButton;
