import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  LoginContext,
  CustomerContext,
  AuthenticationContext,
  GlobalStateContext,
} from '../../../contexts';

import {
  AuthInput,
  RegularButton,
  SocialMediaButtons,
  ActivityIndicatorModal,
} from '../../../components';

import { RegistrationLinkEnum, SocialLoginEnum } from '../../../util/enums';
import { CustomerDTO } from '../../../interfaces';
import LoginPassphraseComponent from './components/LoginPassphraseComponent/LoginPassphraseComponent';
import LoginCredentialsComponent from './components/LoginCredentialsComponent/LoginCredentialsComponent';

const LoginPage = () => {
  const { oAuth } = useContext(LoginContext);
  const { customerInfo, isLoading } = useContext(CustomerContext);
  const { token, saveUserToken } = useContext(AuthenticationContext);
  const { setShowActivityIndicatorModal } = useContext(GlobalStateContext);

  const [loginData, setLoginData] = useState<CustomerDTO | undefined>(
    undefined
  );

  const history = useHistory();

  const navigateToScreen = () => {
    if (!customerInfo || !token) {
      return;
    } else if (!customerInfo.securityQuestion) {
      setShowActivityIndicatorModal(false);
      history.push(RegistrationLinkEnum.SIGNUPPASSPHRASE);
    } else if (!customerInfo.subscription) {
      setShowActivityIndicatorModal(false);
      history.push(RegistrationLinkEnum.SIGNUPCHOOSEPLAN);
    } else if (!customerInfo.creditCards) {
      setShowActivityIndicatorModal(false);
      history.push(RegistrationLinkEnum.SIGNUPAYMENTTYPE);
    } else {
      setShowActivityIndicatorModal(false);
      history.push('/');
      return;
    }
  };

  const navigateToSignUp = () => {
    history.push(RegistrationLinkEnum.SIGNUPMAIN);
  };

  const responseGoogle = (response: any) => {
    console.log(response);
    setShowActivityIndicatorModal(true);
    oAuth(response.tokenId, SocialLoginEnum['google'])
      .then((response) => {
        setShowActivityIndicatorModal(false);
        console.log(response);
        saveUserToken(response.token);
      })
      .catch((error) => {
        console.log(error);
        setShowActivityIndicatorModal(false);
      });
  };

  const onFailureGoogle = (response: any) => {
    console.log('FAIL GOOGL', response);
  };

  const responseFacebook = (response: any) => {
    setShowActivityIndicatorModal(true);
    oAuth(response.accessToken, SocialLoginEnum['facebook'])
      .then((response) => {
        setShowActivityIndicatorModal(false);
        console.log('FB TOKEN:', response.token);
        saveUserToken(response.token);
      })
      .catch((error) => {
        console.log(error);
        setShowActivityIndicatorModal(false);
      });
  };

  const responseApple = (response: any) => {
    // console.log(response);
  };

  useEffect(() => {
    navigateToScreen();
  }, [customerInfo]);

  return (
    <div className="login__page__wrapper">
      {loginData && loginData.userAttributes ? (
        <LoginPassphraseComponent
          userId={loginData.userAttributes.id}
          hint={loginData.securityQuestion?.hint}
          securityQuestionId={loginData.securityQuestion?.id}
        />
      ) : (
        <LoginCredentialsComponent setLoginData={setLoginData} />
      )}
      <p className="sign_in_label">Or sign in with:</p>
      <SocialMediaButtons
        responseApple={responseApple}
        responseFacebook={responseFacebook}
        responseGoogle={responseGoogle}
        onFailureGoogle={onFailureGoogle}
      />
      {/* <div className="password_req_error__wrapper">
        {isErrorVisible && (
          <p className="password_req__error">The credentials are not correct</p>
        )}
      </div> */}
      <p className="missing__account__text">Don't have an account?</p>
      <RegularButton type="black" text="Sign up" onClick={navigateToSignUp} />
      <ActivityIndicatorModal showActivityIndicatorModal={isLoading} />
    </div>
  );
};

export default LoginPage;
