const generateInitials = (firstName: string, lastName: string) => {
  let initials = '';

  if (firstName) {
    if (firstName.length < 1) {
      initials += '';
    } else {
      initials += firstName[0];
    }
  } else {
    initials += '';
  }

  if (lastName) {
    if (lastName.length < 1) {
      initials += '';
    } else {
      initials += lastName[0];
    }
  } else {
    initials += '';
  }

  return initials;
};

export default generateInitials;
