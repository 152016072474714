import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from "react-helmet";

import { RegistrationLinkEnum, DashboardLinkEnum } from './util/enums';

import { AuthenticationContext, CustomerContext } from './contexts';

import {
  AppTemplate,
  RegistrationTemplate,
  RegistrationSecondPartTemplate,
} from './templates';

import {
  LoginPage,
  SignupMainPage,
  SignUpNameNumberPage,
  SignUpPassphrase,
  SignUpChoosePlanPage,
  PaymentTypePage,
  ResetPasswordPage,
  ResetPasswordPage2,
  ResetPasswordPage3,
  ContactsPage,
  TicketsPage,
  SubscriptionPlanPage,
  QuestionsPage,
  ProfilePage,
  CreditCardsPage,
  RedirectPage,
} from './pages/index';

import './scss/main.scss';

function App() {
  const { token } = useContext(AuthenticationContext);
  const { customerInfo } = useContext(CustomerContext);

  const shouldLogin = () =>
    token &&
    customerInfo?.subscription &&
    customerInfo?.creditCards.length > 0 &&
    customerInfo?.securityQuestion.passPhrase;

  const getViewportWidth = () => {
    const width = window.innerWidth;

    if (width < 1440) {
      return 'width=1440';
    }

    return 'width=device-width, initial-scale=1';
  }

  const Authentication = () => {
    return (
      <div className="App">
        <div className="routes">
          <Switch>
            <Route path={RegistrationLinkEnum.RESETPASSWORD2}>
              <ResetPasswordPage2 />
            </Route>

            <Route path={RegistrationLinkEnum.SIGNUPCHOOSEPLAN}>
              <RegistrationSecondPartTemplate>
                <SignUpChoosePlanPage />
              </RegistrationSecondPartTemplate>
            </Route>
            <Route path={RegistrationLinkEnum.SIGNUPAYMENTTYPE}>
              <RegistrationSecondPartTemplate>
                <PaymentTypePage />
              </RegistrationSecondPartTemplate>
            </Route>

            <RegistrationTemplate>
              <Route path={RegistrationLinkEnum.SIGNUPMAIN}>
                <SignupMainPage />
              </Route>
              <Route path={RegistrationLinkEnum.SIGNUPNAMENUMBER}>
                <SignUpNameNumberPage />
              </Route>
              <Route path={RegistrationLinkEnum.SIGNUPPASSPHRASE}>
                <SignUpPassphrase />
              </Route>
              <Route path={RegistrationLinkEnum.RESETPASSWORD}>
                <ResetPasswordPage />
              </Route>
              <Route path={RegistrationLinkEnum.RESETPASSWORD3}>
                <ResetPasswordPage3 />
              </Route>
              <Route exact path={RegistrationLinkEnum.LOGIN}>
                <LoginPage />
              </Route>
            </RegistrationTemplate>
          </Switch>
        </div>
        <div className="redirect_page__wrapper">
          <RedirectPage />
        </div>
        <Helmet>
          <meta name="viewport" content={getViewportWidth()} />
        </Helmet>
      </div>
    );
  };

  const MainApp = () => {
    return (
      <div className="App">
        <Switch>
          <AppTemplate>
            <Route path={DashboardLinkEnum.TICKETS}>
              <TicketsPage />
            </Route>
            <Route path={DashboardLinkEnum.SUBSCRIPTION_PLAN}>
              <SubscriptionPlanPage />
            </Route>
            <Route path={DashboardLinkEnum.QUESTIONS}>
              <QuestionsPage />
            </Route>
            <Route path={DashboardLinkEnum.MYPROFILE}>
              <ProfilePage />
            </Route>
            <Route path={DashboardLinkEnum.CREDITCARDS}>
              <CreditCardsPage />
            </Route>
            <Route exact path={DashboardLinkEnum.CONTACTS}>
              <ContactsPage />
            </Route>
          </AppTemplate>
        </Switch>
        <Helmet>
          <meta name="viewport" content={getViewportWidth()} />
        </Helmet>
      </div>
    );
  };

  // return (
  //   <AppTemplate>
  //     <CreditCardsPage />
  //   </AppTemplate>

  //   // <RegistrationSecondPartTemplate>
  //   //   <PaymentTypePage />
  //   // </RegistrationSecondPartTemplate>
  // );

  // return <Authentication />;
  if (shouldLogin()) {
    return <MainApp />;
  } else {
    return <Authentication />;
  }
}
export default App;
