import React, { useState, useContext, useEffect } from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { CustomerContext, GlobalStateContext } from '../../../contexts';

import {
  InfoLabel,
  ProfileInput,
  RegularButton,
  MainHeader,
  AuthNameInput,
} from '../../../components';

import { UserAtributes, Email, Phone, Address } from '../../../interfaces';

import { DashboardTextEnum, DashboardLinkEnum } from '../../../util/enums';

import generateInitials from '../../../util/functions/generateInitials';

import { CustomerType } from '../../../interfaces';

const ProfilePage = () => {
  const { updateCustomer, getCustomerDataFromToken, customerInfo } =
    useContext(CustomerContext);
  const { setShowActivityIndicatorModal, setSelectedItem } =
    useContext(GlobalStateContext);

  const [editData, setEditData] = useState({} as UserAtributes);
  const [companies, setCompanies] = useState<string[]>([] as string[]);
  const [profilePictureUri, setProfilePictureUri] = useState<
    string | ArrayBuffer | null
  >(customerInfo?.userAttributes.thumbnailUrl || '');

  const [newPhone, setNewPhone] = useState<string>('');
  const [newEmail, setNewEmail] = useState<string>('');
  const [newCompany, setNewCompany] = useState<string>('');
  const [ref, setRef] = useState<any>();

  const creditCardNumber = customerInfo?.creditCards.filter(
    (card) => card.default
  );

  const picture = () => {
    if (typeof profilePictureUri === 'string') return profilePictureUri;
    return '';
  };

  const subscriptionName =
    customerInfo?.subscription.subscriptionAttributes.name;
  const expirationDate = format(
    new Date(customerInfo!.subscription.expires),
    'MM/dd/yy'
  );

  const handleSubmit = () => {
    setShowActivityIndicatorModal(true);
    updateCustomer(editData, companies, picture())
      .then(() => {
        getCustomerDataFromToken();
      })
      .then(() => setShowActivityIndicatorModal(false))
      .catch((error) => {
        console.log(error);
        setShowActivityIndicatorModal(false);
      });
  };

  const history = useHistory();

  const handleNavigateToSubscriptio = () => {
    setSelectedItem(DashboardTextEnum.SUBSCRIPTION_PLAN);
    history.push(DashboardLinkEnum.SUBSCRIPTION_PLAN);
  };

  const handleNavigateToManagePayment = () => {
    history.push(DashboardLinkEnum.CREDITCARDS);
  };

  const onAddPhoneClick = () => {
    const formatedPhone: Phone = {
      number: newPhone,
      isPrimary: false,
      userId: editData.id,
    };

    setEditData({
      ...editData,
      phones: [...editData?.phones, formatedPhone],
    });
  };

  const onRemovePhoneCLick = (index: number) => {
    const filteredPhones = editData.phones.filter((phone, i) => {
      return i !== index;
    });

    setEditData({
      ...editData,
      phones: filteredPhones,
    });
  };

  const onAddEmailClick = () => {
    const formatedEmail: Email = {
      value: newEmail,
      userId: editData.id,
      isPrimary: false,
      label: null,
      isFromFacebook: false,
      isFromGoogle: false,
      isFromApple: false,
    };

    setEditData({
      ...editData,
      emails: [...editData.emails, formatedEmail],
    });
  };

  const onRemoveEmailClick = (index: number) => {
    const filteredEmails = editData.emails.filter((email, i) => {
      return i !== index;
    });

    setEditData({
      ...editData,
      emails: filteredEmails,
    });
  };

  const onAddCompanyClick = () => {
    setCompanies([...companies, newCompany]);
  };

  const onRemoveCompanyClick = (index: number) => {
    const filteredCompanies = companies.filter((company, i) => {
      return i !== index;
    });

    console.log('FILTRIRANE KOMPANIJE;', filteredCompanies);

    setCompanies(filteredCompanies);
  };

  const updateCustomerName = (
    fieldName: keyof UserAtributes,
    element: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (editData) {
      setEditData({
        ...editData,
        [fieldName]: element.target.value,
      });
    }
  };

  const updateCustomerAdress = (fieldName: keyof Address, value: string) => {
    if (editData) {
      setEditData({
        ...editData,
        myAddress: {
          ...editData.myAddress!,
          [fieldName]: value,
        },
      });
    }
  };

  const updateUserPhone = (value: string, index: number) => {
    const phones = editData.phones.slice();
    phones[index].number = value;

    return setEditData({
      ...editData,
      phones,
    });
  };

  const updateUserEmail = (value: string, index: number) => {
    const emails = editData.emails.slice();
    emails[index].value = value;

    return setEditData({
      ...editData,
      emails,
    });
  };

  const updateUserCompany = (value: string, index: number) => {
    const newCompanies = companies.slice();
    newCompanies[index] = value;

    return setCompanies(newCompanies);
  };

  const renderCompanies = () => {
    if (companies?.length) {
      return companies?.map((company, i) => (
        <ProfileInput
          key={i}
          index={i}
          labelName="Company"
          buttonType="delete"
          onRemoveClick={onRemoveCompanyClick}
          setParentData={(value) => updateUserCompany(value, i)}
          data={company}
        />
      ));
    }
  };

  const firstName = customerInfo?.userAttributes.firstName
    ? customerInfo?.userAttributes.firstName
    : '';
  const lastName = customerInfo?.userAttributes.lastName
    ? `${customerInfo?.userAttributes.lastName[0]}.`
    : '';

  const initials = generateInitials(firstName, lastName);

  const onImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64String = reader.result;
        console.log(base64String);
        setProfilePictureUri(base64String);
      };
    }
  };

  const renderProfilePicture = () => {
    if (profilePictureUri) {
      return (
        <img
          src={picture()}
          alt="profile_picture"
          className="profile_picture"
          onClick={handleUploadButtonClick}
        />
      );
    }
    return <p className="initials__text">{initials}</p>;
  };

  const handleUploadButtonClick = () => {
    ref.click();
  };

  useEffect(() => {
    if (customerInfo?.userAttributes) setEditData(customerInfo.userAttributes);

    if (customerInfo?.companies) setCompanies(customerInfo.companies);
  }, [customerInfo]);

  return (
    <div className="profile__page__outer__wrapper">
      <MainHeader title={DashboardTextEnum.MYPROFILE} />
      <div className="profile_page__wrapper">
        <div className="photo_input">
          {renderProfilePicture()}
          {/* <button className="upload_button" onClick={handleUploadButtonClick}>
            Upload
          </button> */}
          <input
            type="file"
            onChange={onImageChange}
            ref={(ref) => setRef(ref)}
            style={{ display: 'none' }}
          />
        </div>
        <div className="content__wrapper">
          <div className="fullname__wrapper">
            <AuthNameInput
              label="First Name"
              value={editData.firstName}
              onChange={(e) => {
                updateCustomerName('firstName', e);
              }}
            />
            <AuthNameInput
              label="Last Name"
              value={editData.lastName}
              onChange={(e) => {
                updateCustomerName('lastName', e);
              }}
            />
          </div>

          <div className="inputs_wrapper">
            <div className="inputs__inner__wrapper">
              {editData?.emails?.length > 0 &&
                editData?.emails.map((email, i) => (
                  <ProfileInput
                    key={i}
                    index={i}
                    labelName="Email"
                    buttonType="delete"
                    onRemoveClick={onRemoveEmailClick}
                    setParentData={(value) => updateUserEmail(value, i)}
                    data={email.value}
                  />
                ))}
              <ProfileInput
                labelName="Add Email"
                buttonType="add"
                onAddClick={onAddEmailClick}
                setParentData={setNewEmail}
                data={newEmail}
              />

              {renderCompanies()}
              <ProfileInput
                labelName="Company"
                buttonType="add"
                onAddClick={onAddCompanyClick}
                setParentData={setNewCompany}
                data={newCompany}
              />
            </div>

            <div className="inputs__inner__wrapper">
              {editData?.phones?.length > 0 &&
                editData?.phones?.map((phoneNumber, i) => (
                  <ProfileInput
                    key={i}
                    index={i}
                    labelName="Phone"
                    buttonType="delete"
                    onRemoveClick={onRemovePhoneCLick}
                    setParentData={(value) => updateUserPhone(value, i)}
                    data={phoneNumber.number}
                  />
                ))}
              <ProfileInput
                labelName="Add Phone"
                buttonType="add"
                onAddClick={onAddPhoneClick}
                setParentData={setNewPhone}
                data={newPhone}
              />
            </div>

            <ProfileInput
              labelName="Address"
              setParentData={(value) => updateCustomerAdress('street', value)}
              data={editData?.myAddress?.street}
            />
            <ProfileInput
              labelName="City"
              setParentData={(value) => updateCustomerAdress('city', value)}
              data={editData?.myAddress?.city}
            />

            <ProfileInput
              labelName="State"
              setParentData={(value) => updateCustomerAdress('state', value)}
              data={editData?.myAddress?.state}
            />

            <ProfileInput
              labelName="Zip"
              setParentData={(value) => updateCustomerAdress('zip', value)}
              data={editData?.myAddress?.zip}
            />

            <ProfileInput
              labelName="Country"
              setParentData={(value) => updateCustomerAdress('country', value)}
              data={editData?.myAddress?.country}
            />
          </div>

          <div className="links__wrapper">
            <div className="payment__type__link__wrapper">
              <div className="payment__type__link__inner__wrapper">
                <p className="label__text">Credit card</p>
                <p className="credit_card_number">
                  **** **** **** {creditCardNumber![0].last4}
                </p>
                <div className="button__wrapper">
                  <RegularButton
                    type="black"
                    text="Manage Payment Type"
                    onClick={handleNavigateToManagePayment}
                  />
                </div>
              </div>
            </div>
            <div className="payment__type__link__wrapper">
              <div className="payment__type__link__inner__wrapper">
                <p className="label__text">Subscription type:</p>
                <p className="value_text">{subscriptionName}</p>
                <div className="expiration__wrapper">
                  <p className="label__text">Expiration date:</p>
                  <p className="value_text">{expirationDate}</p>
                </div>
                <div className="button__wrapper">
                  <RegularButton
                    type="black"
                    text="Manage Subscription"
                    onClick={handleNavigateToSubscriptio}
                  />
                </div>
              </div>
            </div>
            <div className="submit_button__wrapper">
              <RegularButton type="black" text="Save" onClick={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
