export enum TicketStatus {
  'Open',
  'Closed Complete',
  'Close Pending',
  'Pending Completion',
  'Closed Incomplete',
  'Review',
  'Audit',
  'All',
}

export enum TickedPeriod {
  '24 hours',
  'last week',
  'last month',
  'last year',
}
