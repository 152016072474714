import React, { FunctionComponent } from 'react';

interface AuthNoteInputProps {
  label: string;
  value: string | undefined;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
}

const AuthNoteInput: FunctionComponent<AuthNoteInputProps> = ({
  label,
  value,
  onChange,
}) => {
  return (
    <div className="auth_note_input_wrapper">
      <div className="auth_note_input__inner_wrapper">
        <p className="label__note__text">{label}</p>

        <textarea
          rows={5}
          className="text_area"
          value={value}
          onChange={onChange}
        ></textarea>
      </div>
    </div>
  );
};

export default AuthNoteInput;
