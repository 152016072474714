import React, {
    createContext,
    FunctionComponent,
    useEffect,
    useState,
  } from 'react';
  
  interface ITwilioContext {
    token: string;
    saveTwilioToken: (token: string) => void;
    removeTwilioToken: () => void;
    
  }
  
  const TwilioContext = createContext({} as ITwilioContext);
  
  interface TwilioContextProps {
    children: React.ReactNode;
  }
  
  const TwilioContextProvider: FunctionComponent<TwilioContextProps> =
    ({ children }) => {
      const [token, setToken] = useState('');
  
      const saveTwilioToken = (token: string) => {
        setToken(token);
        localStorage.setItem('@twilioToken', token);
      };
  
      const removeTwilioToken = () => {
        setToken('');
        localStorage.removeItem('@twilioToken');
      };
  
      const getTwilioToken = () => {
        let token = localStorage.getItem('@twilioToken');
        if (token) {
          setToken(token);
        }
      };
  
      useEffect(() => {
        if (!token) {
        getTwilioToken();
        }
        console.log('Twilio TOKEN', token);
      }, [token]);
  
      const providerValue = {
        token,
        saveTwilioToken,
        removeTwilioToken,
        getTwilioToken
      };
  
      return (
        <TwilioContext.Provider value={providerValue}>
          {children}
        </TwilioContext.Provider>
      );
    };
  
  export { TwilioContext, TwilioContextProvider };
  