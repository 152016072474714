import { isDate } from 'date-fns';
import React, { FunctionComponent, useState } from 'react';
import moment from 'moment';

import addButton from '../../../assets/addButton.png';
import deleteButton from '../../../assets/deleteButton.png';
import DatePicker from 'react-datepicker';

interface ProfileInputProps {
  labelName?: string;
  buttonType?: 'add' | 'delete';
  index?: number;
  data: any;
  isDate?: boolean;
  isLabelLong?: boolean;
  onAddClick?(): void;
  onRemoveClick?(index: number): void;
  setParentData: React.Dispatch<React.SetStateAction<any>>;
}

const ProfileInput: FunctionComponent<ProfileInputProps> = ({
  labelName,
  buttonType,
  index,
  data,
  isDate,
  isLabelLong,
  onAddClick,
  onRemoveClick,
  setParentData,
}) => {
  const [ref, setRef] = useState<any>();

  const onPressButton = () => {
    if (buttonType === 'add' && data.length !== 0) {
      if (onAddClick) {
        onAddClick();
      }
      setParentData('');
    } else if (buttonType === 'delete') {
      if (!index) {
        index = 0;
      }
      if (onRemoveClick) {
        onRemoveClick(index);
      }
    }
  };

  const manageData = (e: React.ChangeEvent<HTMLInputElement>) => {
    setParentData(e.target.value);
  };

  const manageDataDate = (e: Date) => {
    console.log(e.toString());
    setParentData(e.toString());
  };

  const label_wrapper_class = isLabelLong
    ? 'label__wrapper_long'
    : 'label__wrapper';

  const onInputClick = () => {
    if (!isDate) ref.focus();
  };

  return (
    <div className="profile_input_wrapper" onClick={onInputClick}>
      {labelName && (
        <div className={label_wrapper_class}>
          {buttonType && (
            <button onClick={onPressButton} className="add_delete__button">
              <img
                src={buttonType === 'add' ? addButton : deleteButton}
                alt="addDelete"
                className="button__image"
              />
            </button>
          )}
          <p className="label__text">
            {labelName} {index ? index + 1 : ''}
          </p>
        </div>
      )}
      {isDate ? (
        <DatePicker
          wrapperClassName="date_picker"
          onChange={manageDataDate}
          selected={data.length > 0 ? new Date(data) : data}
          placeholderText="Dates"
          ref={(ref) => setRef(ref)}
        />
      ) : (
        <input
          className="text__input__field"
          value={data}
          onChange={manageData}
          ref={(ref) => setRef(ref)}
        ></input>
      )}
    </div>
  );
};

export default ProfileInput;
