import React, { FunctionComponent, useContext, useState } from 'react';

import { GlobalStateContext } from '../../contexts';

import { DashboardTextEnum } from '../../util/enums';

import { Dashboard, ActivityIndicatorModal } from '../../components/index';

interface AppTemplateProps {
  children: React.ReactNode;
}

const AppTemplate: FunctionComponent<AppTemplateProps> = ({ children }) => {
  const { showActivityIndicatorModal, selectedItem, setSelectedItem } =
    useContext(GlobalStateContext);

  const pageWrapperClass =
    selectedItem === DashboardTextEnum.SUBSCRIPTION_PLAN
      ? 'page_wrapper_subscription'
      : 'page_wrapper';

  return (
    <div className={pageWrapperClass}>
      <Dashboard selectedItem={selectedItem} clickHandler={setSelectedItem} />
      <div className="page_wrapper__content">
        <div className="page_wrapper__content--wrapper">{children}</div>
      </div>
      <ActivityIndicatorModal
        showActivityIndicatorModal={showActivityIndicatorModal}
      />
    </div>
  );
};

export default AppTemplate;
