import React, { FC, useContext, useState } from 'react'
import { useHistory } from 'react-router';
import { AuthInput, RegularButton } from '../../../../../components'
import { GlobalStateContext, LoginContext } from '../../../../../contexts';
import { CustomerDTO } from '../../../../../interfaces';
import { RegistrationLinkEnum } from '../../../../../util/enums';

interface LoginCredentialsComponentProps {
    setLoginData(loginData: CustomerDTO | undefined): void;
}

const LoginCredentialsComponent: FC<LoginCredentialsComponentProps> = (props) => {
    const { setLoginData } = props;

    const { setShowActivityIndicatorModal } = useContext(GlobalStateContext);
    const { userLogin } = useContext(LoginContext);

    const initialData = {
        email: '',
        password: '',
    };
  
    const [inputData, setInputData] = useState(initialData);
    const [isErrorVisible, setIsErrorVisible] = useState(false);

    const history = useHistory();

    const navigateToForgotPassword = () => {
        history.push(RegistrationLinkEnum.RESETPASSWORD);
    };

    const inputHandler = (
        fieldName: string,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setInputData({
            ...inputData,
            [fieldName]: event.target.value,
        });
    };

    const handleLogin = () => {
        setShowActivityIndicatorModal(true);

        userLogin(inputData).then((response) => {
            console.log(response.status);

            if (response.status === 404 || response.status === 401) {
                setIsErrorVisible(true);

                return setShowActivityIndicatorModal(false);
            }
            if (!response.data.token) {
                setLoginData(response.data);
            }
            
            setShowActivityIndicatorModal(false);
        });
    };

    return (
        <>
            <p className="welcome__text">Welcome</p>
            <AuthInput
                value={inputData.email}
                onChange={(e) => inputHandler('email', e)}
                placeholder="E-mail"
                onBlur={() => {}}
                textColor="black"
                onFocus={() => setIsErrorVisible(false)}
            />
            <AuthInput
                value={inputData.password}
                password
                onChange={(e) => inputHandler('password', e)}
                placeholder="Password"
                onBlur={() => {}}
                textColor="black"
                errorText="The credentials are not correct"
                isErrorVisible={isErrorVisible}
                onFocus={() => setIsErrorVisible(false)}
            />
            <button
                className="forgot__password__button"
                onClick={navigateToForgotPassword}
            >
                <p className="forgot__password__text">Forgot your password?</p>
            </button>
            <RegularButton type="white" text="Log in" onClick={handleLogin} />
        </>
    )
}

export default LoginCredentialsComponent
