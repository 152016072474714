import React, { FunctionComponent } from 'react';
import './RegistrationHeader.scss';

import { BackArrowButton, RegularButton } from '../../../components';

interface RegistrationHeaderProps {
  text: string;
  isButtonDisabled: boolean;
  onClickBack: React.MouseEventHandler<HTMLButtonElement>;
  onClickNext: React.MouseEventHandler<HTMLButtonElement>;
}

const RegistrationHeader: FunctionComponent<RegistrationHeaderProps> = ({
  text,
  isButtonDisabled,
  onClickBack,
  onClickNext,
}) => {
  return (
    <div className="registration__header__wrapper">
      <div className="button__wrapper__back">
        <BackArrowButton onClick={onClickBack} />
      </div>
      <p className="header__text">{text}</p>
      <div className="button__wrapper__next">
        <RegularButton
          text="Next"
          onClick={onClickNext}
          type="greyWhenDisabled"
          disabled={isButtonDisabled}
        />
      </div>
    </div>
  );
};

export default RegistrationHeader;
