import React, { FunctionComponent } from 'react';

interface AddCreditCardProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const AddCreditCard: FunctionComponent<AddCreditCardProps> = ({ onClick }) => {
  return (
    <button className="add__credit__card__wrapper" onClick={onClick}>
      <p className="plus__text">+</p>
      <p className="add__card__text">Add Card</p>
    </button>
  );
};

export default AddCreditCard;
