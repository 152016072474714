import React, { FunctionComponent } from 'react';

interface AuthInputProps {
  value: string;
  placeholder: string;
  password?: boolean;
  textColor: string;
  errorText?: string;
  isErrorVisible?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const AuthInput: FunctionComponent<AuthInputProps> = ({
  value,
  placeholder,
  password,
  textColor,
  isErrorVisible,
  errorText,
  onFocus,
  onBlur,
  onChange,
}) => {
  const errorTextClassName = isErrorVisible
    ? 'error__auth__text__visible'
    : 'error__auth__text__not__visible';

  return (
    <div className="auth__input__wrapper">
      <input
        onFocus={onFocus}
        type={password ? 'password' : 'text'}
        onBlur={onBlur}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={
          textColor === 'black' ? 'auth__input__black' : 'auth__input__red'
        }
      />
      <div className="error__wrapper">
        <p className={errorTextClassName}>{errorText}</p>
      </div>
    </div>
  );
};

export default AuthInput;
