import React, { FunctionComponent } from 'react';
import './Ticket.scss';
import { TicketStatus } from '../../../util/enums';

interface TicketProps {
  ticketID: number;
  status: TicketStatus;

  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const Ticket: FunctionComponent<TicketProps> = ({
  ticketID,
  status,
  onClick,
}) => {
  let ticketStatusClass;
  let textClass;

  if (status === TicketStatus.Open) {
    ticketStatusClass = 'ticket__status_red';
    textClass = 'ticket__status__text_14';
  } else if (status === TicketStatus['Closed Complete']) {
    ticketStatusClass = 'ticket__status_green';
    textClass = 'ticket__status__text_14';
  } else if (status === TicketStatus['Closed Incomplete']) {
    ticketStatusClass = 'ticket__status_orange';
    textClass = 'ticket__status__text_13';
  } else if (status === TicketStatus['Pending Completion']) {
    ticketStatusClass = 'ticket__status_orange';
    textClass = 'ticket__status__text_12';
  } else if (status === TicketStatus.Audit) {
    ticketStatusClass = 'ticket__status_orange';
    textClass = 'ticket__status__text_14';
  } else if (status === TicketStatus['Close Pending']) {
    ticketStatusClass = 'ticket__status_orange';
    textClass = 'ticket__status__text_14';
  } else if (status === TicketStatus.Review) {
    ticketStatusClass = 'ticket__status_orange';
    textClass = 'ticket__status__text_14';
  }

  return (
    <button className="ticket__wrapper" onClick={onClick}>
      <p className="ticketID__label">Ticket ID: {ticketID.toString()}</p>
      <div className={ticketStatusClass}>
        <p className={textClass}>{TicketStatus[status]}</p>
      </div>
    </button>
  );
};

export default Ticket;
