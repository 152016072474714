import React, { useState, useContext, useEffect } from 'react';
import {
  subDays,
  subYears,
  setYear,
  startOfYear,
  lastDayOfYear,
  setMonth,
  endOfMonth,
  startOfMonth,
  getWeek,
  setWeek,
  endOfWeek,
  startOfWeek,
  setHours,
} from 'date-fns';
import { CustomerContext, GlobalStateContext } from '../../../contexts';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import {
  Ticket,
  TicketModal,
  MainHeader,
  DropDownTicket,
} from '../../../components';

import { ITicket } from '../../../interfaces';

import {
  DashboardTextEnum,
  TicketStatus,
  TickedPeriod,
} from '../../../util/enums';

const TicketsPage = () => {
  const { getAllTickets, getTicketsByDate } = useContext(CustomerContext);
  const { setShowActivityIndicatorModal } = useContext(GlobalStateContext);

  const [allTickets, setAllTickets] = useState([] as ITicket[]);
  const [searchData, setSearchData] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [ticketModalIndex, setTicketModalIndex] = useState(0);
  const [isTicketDropdownOpened, setIsTicketDropdownOpened] = useState(false);
  const [isPeriodDropdownOpened, setIsPeriodDropdownOpened] = useState(false);
  const [ticketDropDownHeadline, setTicketDropDownHeadLine] =
    useState('Ticket status');
  const [periodDropDownHeadline, setPeriodDropDownHeadLine] =
    useState('24h/week/m/y');
  const [startCalendarDate, setStartCalendarDate] = useState<any>(null);
  const [endCalendarDate, setEndCalendarDate] = useState<any>(null);

  const openModal = (index: number) => {
    setTicketModalIndex(index);
    setShowModal(true);
  };

  const ticketDropDownHandler = () => {
    setIsTicketDropdownOpened(!isTicketDropdownOpened);
  };

  const periodDropDonwHandler = () => {
    setIsPeriodDropdownOpened(!isPeriodDropdownOpened);
  };

  const filterTicketsByID = (tickets: ITicket[]) => {
    return tickets.filter((ticket) => {
      const id = ticket.id.toString();

      const idIncluded = id.includes(searchData.toLocaleLowerCase());

      return idIncluded;
    });
  };

  const filterTicketsByStatus = (tickets: ITicket[]) => {
    return tickets.filter((ticket) => {
      const ticketStatus = ticket.status;

      const ticketStatusIncluded = TicketStatus[ticketStatus].includes(
        ticketDropDownHeadline
      );

      return ticketStatusIncluded;
    });
  };

  const renderTicekts = (tickets: ITicket[]) => {
    let ticketsToRender;
    const filteredTicketsByID = filterTicketsByID(tickets);

    const isNotTicketStatus = ticketDropDownHeadline !== 'Ticket status';
    const isAll = ticketDropDownHeadline === 'All';

    if (isAll) {
      ticketsToRender = filteredTicketsByID;
    } else if (isNotTicketStatus) {
      ticketsToRender = filterTicketsByStatus(filteredTicketsByID);
    } else {
      ticketsToRender = filteredTicketsByID;
    }

    return ticketsToRender.map((ticket, index) => (
      <Ticket
        key={index}
        ticketID={ticket.id}
        status={ticket.status}
        onClick={() => openModal(index)}
      />
    ));
  };

  const onCalendarChange = (dates: any) => {
    const [start, end] = dates;
    setStartCalendarDate(start);
    setEndCalendarDate(end);
  };

  const ticketStatusLabels = Object.keys(TicketStatus)
    .map((key) => TicketStatus[+key])
    .filter((value) => typeof value === 'string') as string[];

  const periodStatusLabels = Object.keys(TickedPeriod)
    .map((key) => TickedPeriod[+key])
    .filter((value) => typeof value === 'string') as string[];

  useEffect(() => {
    setShowActivityIndicatorModal(true);
    getAllTickets()
      .then((response) => setAllTickets(response))
      .then(() => {
        setShowActivityIndicatorModal(false);
      })
      .catch((error) => {
        console.log(error);
        setShowActivityIndicatorModal(false);
      });
  }, []);

  useEffect(() => {
    if (periodDropDownHeadline !== '24h/week/m/y') {
      setShowActivityIndicatorModal(true);
      let startDate = subYears(new Date(), 1).getTime();
      let endDate = new Date().getTime();

      if (periodDropDownHeadline === TickedPeriod[0]) {
        startDate = subDays(new Date(), 1).getTime();
      } else if (periodDropDownHeadline === TickedPeriod[1]) {
        const previousWeek = getWeek(new Date(), { weekStartsOn: 0 }) - 1;
        const datePreviousWeek = setWeek(new Date(), previousWeek);
        startDate = startOfWeek(datePreviousWeek, {
          weekStartsOn: 0,
        }).getTime();
        endDate = endOfWeek(datePreviousWeek, { weekStartsOn: 0 }).getTime();
      } else if (periodDropDownHeadline === TickedPeriod[2]) {
        const previousMonth = new Date().getMonth() - 1;
        const datePreviousMonth = setMonth(new Date(), previousMonth);
        startDate = startOfMonth(datePreviousMonth).getTime();
        endDate = endOfMonth(datePreviousMonth).getTime();
      } else if (periodDropDownHeadline === TickedPeriod[3]) {
        const previousYear = new Date().getFullYear() - 1;
        const dateLastYear = setYear(new Date(), previousYear);
        startDate = startOfYear(dateLastYear).getTime();
        endDate = lastDayOfYear(dateLastYear).getTime();
      }

      console.log(new Date(startDate), new Date(endDate));

      getTicketsByDate(startDate, endDate).then((response) => {
        setAllTickets(response);
        setShowActivityIndicatorModal(false);
      });
    }
  }, [periodDropDownHeadline]);

  useEffect(() => {
    if (endCalendarDate) {
      const lastHour = setHours(endCalendarDate, 23);
      console.log(startCalendarDate);
      console.log(lastHour);

      getTicketsByDate(startCalendarDate.getTime(), lastHour.getTime()).then(
        (response) => {
          setAllTickets(response);
          setShowActivityIndicatorModal(false);
        }
      );
    }
  }, [endCalendarDate]);

  return (
    <div className="ticket_page">
      <MainHeader title={DashboardTextEnum.TICKETS} />
      <div className="tickets__page__wrapper">
        <div className="search__wrapper">
          <label className="input__wraper">
            <input
              placeholder="Search by Ticket ID"
              value={searchData}
              onChange={(e) => {
                setSearchData(e.target.value);
              }}
              className="contacts__search__input"
            />
          </label>

          <div className="other__types__searches">
            <DropDownTicket
              headline={ticketDropDownHeadline}
              isDropdownOpened={isTicketDropdownOpened}
              statusLabels={ticketStatusLabels}
              dropDownHandler={ticketDropDownHandler}
              setStatusLabel={setTicketDropDownHeadLine}
            />
            <DropDownTicket
              setStatusLabel={setPeriodDropDownHeadLine}
              headline={periodDropDownHeadline}
              isDropdownOpened={isPeriodDropdownOpened}
              statusLabels={periodStatusLabels}
              dropDownHandler={periodDropDonwHandler}
            />
            <DatePicker
              selectsRange
              className="date_picker"
              onChange={onCalendarChange}
              selected={startCalendarDate}
              startDate={startCalendarDate}
              endDate={endCalendarDate}
              maxDate={new Date()}
              showDisabledMonthNavigation
              placeholderText="Dates"
            />
          </div>
        </div>

        <div className="tickets__wrapper">
          {allTickets.length > 0 && renderTicekts(allTickets)}
        </div>
        <TicketModal
          allTickets={allTickets}
          showModal={showModal}
          setShowModal={setShowModal}
          index={ticketModalIndex}
        />
      </div>
    </div>
  );
};

export default TicketsPage;
