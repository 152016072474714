import React, { FunctionComponent } from 'react';
import { Subscription } from '../../../interfaces';

interface SubscriptionTextProps {
  subscriptionType: Subscription;
}

const SubscriptionText: FunctionComponent<SubscriptionTextProps> = ({
  subscriptionType,
}) => {
  const {
    numOfSupportCalls,
    numOfPriorityContactGroups,
    numOfMinsPerSupportCall,
    numOfMinsPerCallToContact,
    priceOfAdditionalConciergeSupportPerMin,
    priceOfAdditionalCallToContactPerMin,
    name,
  } = subscriptionType;

  return name === 'Basic' ? (
    <div className="subscriptiontext__main__wrapper">
      <p className="subscription__main__text">
        • Automated, secure cloud backup of
        <br />
        unlimited number of your contacts
      </p>
      <p className="subscription__main__text">
        • Unlimited retrieval and restore of all stored
        <br />
        contacts, to any subscriber device
      </p>
      <p className="subscription__main__text">
        • Export/backup contact list in any <br />
        file-format
      </p>
      <p className="subscription__main__text">• No live concierge support</p>
    </div>
  ) : (
    <div className="subscriptiontext__main__wrapper">
      <p className="subscription__main__text">
        • Unlimited contact storage & retrieval
      </p>
      <p className="subscription__main__text">
        • <b>{numOfSupportCalls}</b> concierge support calls per year
      </p>

      <p className="subscription__main__text">
        • Define <b>{numOfPriorityContactGroups}</b> contacts in a Priority
        Group{'\n'}
        for easy one-to-many messaging
      </p>

      <p className="subscription__main__text">
        • <b>{numOfMinsPerSupportCall}</b> minutes per support call
      </p>

      <p className="subscription__main__text">
        • <b>{numOfMinsPerCallToContact}</b> minutes per assisted
        call-to-contact
      </p>

      <p className="subscription__main__text">
        • <b>${priceOfAdditionalConciergeSupportPerMin}/minute,</b> additional
        concierge{'\n'}support time
      </p>
      <p className="subscription__main__text">
        • <b>${priceOfAdditionalCallToContactPerMin}/minute,</b> additional
        {'\n'}
        call-to-contact time
      </p>
    </div>
  );
};

export default SubscriptionText;
