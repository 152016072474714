import React, { FunctionComponent } from 'react';

interface AuthNameInputProps {
  label: string;
  value: string | undefined;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const AuthNameInput: FunctionComponent<AuthNameInputProps> = ({
  label,
  value,
  onChange,
}) => {
  return (
    <div className="auth_name_input_wrapper">
      <div className="auth_name_input__inner_wrapper">
        <p className="label__name__text">{label}</p>
        <input className="value_input" value={value} onChange={onChange} />
      </div>
    </div>
  );
};

export default AuthNameInput;
