import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import {
  LoginContext,
  GlobalStateContext,
  AuthenticationContext,
} from '../../../contexts';

import {
  AuthInput,
  RegularButton,
  BackArrowButton,
  SocialMediaButtons,
} from '../../../components';

import { RegistrationLinkEnum, SocialLoginEnum } from '../../../util/enums';

type textColor = 'black' | 'red';

const SignupMainPage = () => {
  const { handleRegistrationInputs, registerData, oAuth } =
    useContext(LoginContext);
  const { setShowActivityIndicatorModal } = useContext(GlobalStateContext);
  const { saveUserToken } = useContext(AuthenticationContext);

  const [inputEmailTextColor, setinputEmailTextColor] =
    useState<textColor>('black');
  const [inputPasswordTextColor, setInputPasswordTextColor] =
    useState<textColor>('black');
  const [isEmailErrorVisible, setIsEmailErrorVisible] = useState(false);
  const [isPasswordErrorVisible, setIsPasswordErrorVisible] = useState(false);
  const [isPassswordReqErrorVisible, setIsPasswordReqErrorVisible] =
    useState(false);

  const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  const regexPassword =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/; // Minimum eight characters, at least one letter and one number and one speical caracheter

  const validateEmail = () => {
    // if (registerData.email === '') {
    //   setinputEmailTextColor('black');
    // } else if (
    //   regexEmail.test(String(registerData.email)) === false &&
    //   registerData.email
    // ) {
    //   setinputEmailTextColor('red');
    // } else if (regexEmail.test(String(registerData.email)) === true) {
    //   setinputEmailTextColor('black');
    // }
  };

  const validatePassword = () => {
    // if (registerData.password === '') {
    //   setInputPasswordTextColor('black');
    //   setIsPasswordReqErrorVisible(false);
    // } else if (
    //   regexPassword.test(String(registerData.password)) === false &&
    //   registerData.password
    // ) {
    //   setInputPasswordTextColor('red');
    //   setIsPasswordReqErrorVisible(true);
    // } else if (regexPassword.test(String(registerData.password)) === true) {
    //   setInputPasswordTextColor('black');
    // }
  };

  const onInputFocuse = () => {
    setIsPasswordErrorVisible(false);
    setIsEmailErrorVisible(false);
    setIsPasswordReqErrorVisible(false);

    if (!registerData.password) setIsPasswordErrorVisible(false);
    if (!registerData.email) setIsEmailErrorVisible(false);
  };

  const handleError = () => {
    if (!registerData.password) setIsPasswordErrorVisible(true);
    if (!registerData.email) setIsEmailErrorVisible(true);
  };

  const history = useHistory();

  const navigateBackwards = () => {
    history.goBack();
  };

  const navigateToSignUpNameNumberPage = () => {
    if (registerData.email && registerData.password) {
      history.push(RegistrationLinkEnum.SIGNUPNAMENUMBER);
    }
  };

  const handleSignUpButton = () => {
    handleError();

    if (
      regexEmail.test(String(registerData.email)) === false ||
      !registerData.email
    ) {
      return setIsEmailErrorVisible(true);
    }
    if (
      regexPassword.test(String(registerData.password)) === false ||
      !registerData.password
    ) {
      return setIsPasswordReqErrorVisible(true);
    }

    return navigateToSignUpNameNumberPage();
  };

  const responseGoogle = (response: any) => {
    console.log(response.tokenId);
    setShowActivityIndicatorModal(true);
    oAuth(response.tokenId, SocialLoginEnum['google'])
      .then((response) => {
        setShowActivityIndicatorModal(false);
        console.log(response);
        saveUserToken(response.token);
      })
      .then(() => history.push(RegistrationLinkEnum.SIGNUPNAMENUMBER))
      .catch((error) => {
        console.log(error);
        setShowActivityIndicatorModal(false);
      });
  };

  const onFailureGoogle = (response: any) => {
    console.log(response);
  };

  const responseFacebook = (response: any) => {
    console.log('FACEBOOK RESPOMNSE', response);
    setShowActivityIndicatorModal(true);
    oAuth(response.accessToken, SocialLoginEnum['facebook'])
      .then((response) => {
        setShowActivityIndicatorModal(false);
        console.log(response.token);
        saveUserToken(response.token);
      })
      .then(() => history.push(RegistrationLinkEnum.SIGNUPNAMENUMBER))
      .catch((error) => {
        console.log(error);
        setShowActivityIndicatorModal(false);
      });
  };

  const responseApple = (response: any) => {
    // console.log(response);
  };

  return (
    <div className="signupmain__page__wrapper">
      <BackArrowButton onClick={navigateBackwards} />
      <div className="inner__wrapper">
        <p className="bussiness__partners__text">
          For business partner use only.
        </p>
        <AuthInput
          value=""
          onChange={(e) => {
            handleRegistrationInputs('agentId', e);
          }}
          placeholder="Agent ID"
          textColor="black"
          //onBlur={() => {}}
        />
        <p className="create__new__account__text">Create new account</p>
        <AuthInput
          value={registerData.email}
          onChange={(e) => {
            handleRegistrationInputs('email', e);
          }}
          placeholder="E-mail*"
          textColor={inputEmailTextColor}
          // onBlur={validateEmail}
          onFocus={onInputFocuse}
          errorText="Please enter correct e-mail"
          isErrorVisible={isEmailErrorVisible}
        />
        <AuthInput
          value={registerData.password}
          onChange={(e) => {
            handleRegistrationInputs('password', e);
          }}
          placeholder="Password*"
          password
          textColor={inputPasswordTextColor}
          // onBlur={validatePassword}
          onFocus={onInputFocuse}
          errorText="Please enter your password"
          isErrorVisible={isPasswordErrorVisible}
        />

        <RegularButton
          text="Sign Up"
          type="black"
          onClick={handleSignUpButton}
        />
        <p className="social__media__text">Or sign up with:</p>
        <SocialMediaButtons
          onFailureGoogle={onFailureGoogle}
          responseApple={responseApple}
          responseGoogle={responseGoogle}
          responseFacebook={responseFacebook}
        />
        <div className="password_req_error__wrapper">
          {isPassswordReqErrorVisible && (
            <p className="password_req__error">
              Your password must be at least 8 characters long, have a mixture
              of uppercase and lowercase letters, contain at least one number
              and a special character!
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignupMainPage;
