import React, { FunctionComponent, useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { DropdownButton } from '../..';

import {
  AuthenticationContext,
  CustomerContext,
  GlobalStateContext,
} from '../../../contexts';

import { DashboardLinkEnum, DashboardTextEnum } from '../../../util/enums';

import generateInitials from '../../../util/functions/generateInitials';

import { CustomerType } from '../../../interfaces';

interface MainHeaderProps {
  title: DashboardTextEnum;
}

const MainHeader: FunctionComponent<MainHeaderProps> = ({ title }) => {
  const { customerInfo, setCustomerInfo } = useContext(CustomerContext);
  const { removeUserToken } = useContext(AuthenticationContext);
  const { setSelectedItem } = useContext(GlobalStateContext);

  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const firstName = customerInfo?.userAttributes?.firstName
    ? customerInfo?.userAttributes.firstName
    : '';
  const lastName = customerInfo?.userAttributes.lastName
    ? `${customerInfo?.userAttributes.lastName[0]}.`
    : '';

  const fullName = `${firstName} ${lastName}`;
  const initials = generateInitials(firstName, lastName);

  const titleText = title === 'Questions' ? 'Help' : title;

  const history = useHistory();

  const signOut = () => {
    removeUserToken();
    setCustomerInfo(undefined);
    setSelectedItem(DashboardTextEnum.CONTACTS);
    history.push('/');
  };

  const arrowHandler = () => {
    setIsButtonClicked(!isButtonClicked);
  };

  const navigateToMyProfilePage = () => {
    setSelectedItem(DashboardTextEnum.MYPROFILE);
    setIsButtonClicked(false);
  };

  const renderProfilePicture = (customerInfo: CustomerType | undefined) => {
    if (customerInfo?.userAttributes.thumbnailUrl) {
      return (
        <img
          src={customerInfo.userAttributes.thumbnailUrl}
          alt="profile_picture"
          className="profile_picture"
        />
      );
    }
    return <p className="initials__text">{initials}</p>;
  };

  console.log(customerInfo);

  return (
    <div className="mainheader__wrapper">
      <p className="mainheader__headline">{titleText}</p>
      <div className="profile__wrapper">
        <div className="dropdown" onClick={arrowHandler}>
          <div className="divider" />
          <div className="dropdown-content">
            <div className="triangle"></div>
            <div className="edit_profile_box">
              <Link
                to={DashboardLinkEnum.MYPROFILE}
                onClick={navigateToMyProfilePage}
              >
                <p className="profile__text">Edit Profile</p>
              </Link>
            </div>
            <div className="log_out_box">
              <p className="profile__text" onClick={signOut}>
                Log out
              </p>
            </div>
          </div>

          <p className="profile__text">{fullName}</p>
          <DropdownButton arrowHandler={() => {}} />
        </div>

        <div className="initials__circle">
          {renderProfilePicture(customerInfo)}
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
