import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { LoginContext, GlobalStateContext } from '../../../contexts';

import { AuthInput, RegularButton, BackArrowButton } from '../../../components';

import { RegistrationLinkEnum } from '../../../util/enums';

const SignUpNameNumberPage = () => {
  let { handleRegistrationInputs, registerData, registerUser } =
    useContext(LoginContext);

  const { setShowActivityIndicatorModal } = useContext(GlobalStateContext);
  const [isFirstNameErrorVisible, setIsFirstNameErrorVisible] = useState(false);
  const [isLastNameErrorVisible, setIsLastNameErrorVisible] = useState(false);
  const [isPhoneNumberErrorVisible, setIsPhoneNumberErrorVisible] =
    useState(false);
  const [isEmailNumberErrorVisible, setisEmailPasswordErrorVisible] =
    useState(false);
  const [isPhoneNumberFormatVisible, setIsPhoneNumberFormatVisible] =
    useState(false);

  const history = useHistory();

  const regexPhoneNumber = /^[^a-zA-Z]*$/;

  const navigateBackwards = () => {
    history.goBack();
  };

  const navigateToSignUpPassphrase = () => {
    history.push(RegistrationLinkEnum.SIGNUPPASSPHRASE);
  };

  const handleError = () => {
    if (!registerData.firstName) setIsFirstNameErrorVisible(true);
    if (!registerData.lastName) setIsLastNameErrorVisible(true);
    if (!registerData.phoneNumber) setIsPhoneNumberErrorVisible(true);
  };

  const setEmptyInputs = () => {
    registerData.backupPhoneNumber = '';
    registerData.phoneNumber = '';
    registerData.email = '';
    registerData.password = '';
    registerData.firstName = '';
    registerData.lastName = '';
  };

  const onInputFocuse = () => {
    setisEmailPasswordErrorVisible(false);
    setIsPhoneNumberFormatVisible(false);
    if (!registerData.firstName) setIsFirstNameErrorVisible(false);
    if (!registerData.lastName) setIsLastNameErrorVisible(false);
    if (!registerData.phoneNumber) setIsPhoneNumberErrorVisible(false);
  };

  const handleNextButton = () => {
    handleError();

    if (
      !registerData.firstName ||
      !registerData.lastName ||
      !registerData.phoneNumber
    )
      return;

    if (
      (registerData.phoneNumber &&
        regexPhoneNumber.test(String(registerData.phoneNumber)) === false) ||
      (registerData.backupPhoneNumber &&
        regexPhoneNumber.test(String(registerData.backupPhoneNumber)) === false)
    ) {
      return setIsPhoneNumberFormatVisible(true);
    }

    setShowActivityIndicatorModal(true);
    registerUser().then((status) => {
      if (status === 200) {
        navigateToSignUpPassphrase();
        setEmptyInputs();
      } else if (status === 400) {
        setisEmailPasswordErrorVisible(true);
      }
      setShowActivityIndicatorModal(false);
    });
  };

  return (
    <div className="signup__name__number__page__wrapper">
      <BackArrowButton onClick={navigateBackwards} />
      <div className="inner__wrapper">
        <p className="your__name__text">Your Name</p>
        <AuthInput
          value={registerData.firstName}
          onChange={(e) => {
            handleRegistrationInputs('firstName', e);
          }}
          placeholder="First Name*"
          textColor="black"
          onBlur={() => {}}
          onFocus={onInputFocuse}
          errorText="Please enter your First Name"
          isErrorVisible={isFirstNameErrorVisible}
        />

        <AuthInput
          value={registerData.lastName}
          onChange={(e) => {
            handleRegistrationInputs('lastName', e);
          }}
          placeholder="Last Name*"
          textColor="black"
          onBlur={() => {}}
          onFocus={onInputFocuse}
          errorText="Please enter your Last Name"
          isErrorVisible={isLastNameErrorVisible}
        />

        <p className="number__text">Number</p>
        <AuthInput
          value={registerData.phoneNumber}
          onChange={(e) => {
            handleRegistrationInputs('phoneNumber', e);
          }}
          placeholder="Phone number*"
          textColor="black"
          onBlur={() => {}}
          onFocus={onInputFocuse}
          errorText="Please enter your Phone Number"
          isErrorVisible={isPhoneNumberErrorVisible}
        />

        <AuthInput
          value={registerData.backupPhoneNumber}
          onChange={(e) => {
            handleRegistrationInputs('backupPhoneNumber', e);
          }}
          placeholder="Backup phone number"
          textColor="black"
          onBlur={() => {}}
        />
        <div className="error__wrapper">
          {isEmailNumberErrorVisible && (
            <p className="error__text">
              Email or phone number is already used, please try again.
            </p>
          )}
          {isPhoneNumberFormatVisible && (
            <p className="error__text">Phone number cannot containt letters</p>
          )}
        </div>
        <div className="button__wrapper">
          <RegularButton text="Next" type="black" onClick={handleNextButton} />
        </div>
      </div>
    </div>
  );
};

export default SignUpNameNumberPage;
