import React from 'react';
import { useHistory } from 'react-router-dom';

import { RegularButton, BackArrowButton } from '../../../components';

import { RegistrationLinkEnum } from '../../../util/enums';

import logo from '../../../assets/logo.png';
import { backgroundAuthText } from '../../../util/appText';

const ResetPasswordPage2 = () => {
  const history = useHistory();

  const navigateBackwards = () => {
    history.goBack();
  };

  const navigateToTheNextPage = () => {
    history.push(RegistrationLinkEnum.RESETPASSWORD3);
  };

  return (
    <div className="resetpassword2__page__wrapper">
      <div className="logo__phrase__wrapper">
        <div className="logo__phrase__wrapper__content">
          <img src={logo} alt="logo" className="logo" />
          <p className="phrase__text">{backgroundAuthText}</p>
        </div>
      </div>
      <div className="content__wrapper">
        <BackArrowButton onClick={navigateBackwards} />
        <div className="inner__wrapper">
          <p className="reset__password__headline">Reset Password</p>
          <p className="reset__password__text">
            Please check your email for a link from
            <br />
            725Contact to reset your password
          </p>

          <div className="button__wrapper">
            <RegularButton
              text="OK"
              type="black"
              onClick={navigateToTheNextPage}
            />
          </div>
          <div className="yes_logo" />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage2;
