import React, { FunctionComponent } from 'react';

import dropdownArrow from '../../../assets/dropdown_arrow.png';

interface DropdownButtonProps {
  arrowHandler: () => void;
  onBlur?: () => void;
}

const DropdownButton: FunctionComponent<DropdownButtonProps> = ({
  arrowHandler,
  onBlur,
}) => {
  return (
    <button
      className="dropdownarrow__wrapper"
      onClick={arrowHandler}
      onBlur={onBlur}
    >
      <img
        src={dropdownArrow}
        alt="dropdownarrow"
        className="dropdownarrow__wrapper_image_up"
      />
    </button>
  );
};

export default DropdownButton;
