import React, {
  createContext,
  useContext,
  FunctionComponent,
  useState,
  useEffect,
} from 'react';

import { AxiosContext } from './AxiosContext';
import { AuthenticationContext } from './AuthenticationContext';

import { CustomerType, UserAtributes, ITicket } from '../interfaces';

interface CustomerContextProviderProps {
  children: React.ReactNode;
}

interface CustomerContextState {
  customerInfo?: CustomerType;
  isLoading: boolean;
  getCustomerDataFromToken: () => Promise<void>;
  updateCustomer: (
    customerEditedData: UserAtributes,
    companies: string[],
    profilePictureUri: string
  ) => Promise<void>;
  sendFeedback: (message: string) => Promise<void>;
  getAllTickets: () => Promise<any>;
  getTicketsByDate: (startDate: number, endDate: number) => Promise<any>;
  requestResetPassword: (email: string) => Promise<number | void>;
  resetPassword: (resetPasswordInputs: {
    password: string;
    repeatPassword: string;
    hash: string;
  }) => Promise<void>;
  setCustomerInfo: React.Dispatch<
    React.SetStateAction<CustomerType | undefined>
  >;
}

const CustomerContext = createContext<CustomerContextState>(
  {} as CustomerContextState
);

const CustomerContextProvider: FunctionComponent<CustomerContextProviderProps> =
  (props) => {
    const { axiosInstance } = useContext(AxiosContext);
    const { token } = useContext(AuthenticationContext);

    const [customerInfo, setCustomerInfo] = useState<
      CustomerType | undefined
    >();
    const [isLoading, setIsLoading] = useState(false)

    const getCustomerDataFromToken = () => {
      return axiosInstance
        .get('/customer/getCustomerDataFromToken')
        .then((res) => {
          console.log('CUSTOMER DATA:', res.data);
          const customerData = res.data;

          if (!customerData.myAddresses) {
            customerData.myAddresses = {
              zip: '',
              street: '',
              country: '',
              state: '',
              city: '',
            };
          }

          if (!customerData.myAddresses.zip) {
            customerData.myAddresses.zip = '';
          }
          if (!customerData.myAddresses.street) {
            customerData.myAddresses.street = '';
          }
          if (!customerData.myAddresses.country) {
            customerData.myAddresses.country = '';
          }
          if (!customerData.myAddresses.state) {
            customerData.myAddresses.state = '';
          }
          if (!customerData.myAddresses.city) {
            customerData.myAddresses.city = '';
          }
          setCustomerInfo(customerData);
        })
        .catch((error) => {
          console.log('error', error);
          console.log('GET CUSTOMER DATA FROM TOKEN ERROR: ', error);
        });
    };

    const updateCustomer = (
      customerEditedData: UserAtributes,
      companies: string[],
      profilePictureUri: string
    ) => {
      const { emails, firstName, lastName, phones, myAddress } =
        customerEditedData;

      const emailsToUpdate = emails.map((email) => {
        return { value: email.value };
      });

      const phonesToUpdate = phones.map((phone) => {
        return { number: phone.number };
      });

      const myAddressToUpdate = {
        zip: myAddress?.zip || undefined,
        state: myAddress?.state || undefined,
        street: myAddress?.street || undefined,
        country: myAddress?.country || undefined,
        city: myAddress?.city || undefined,
      };

      const dataToBeUpdated = {
        firstName,
        lastName,
        emails: emailsToUpdate.length ? emailsToUpdate : undefined,
        phones: phonesToUpdate.length ? phonesToUpdate : undefined,
        myAddress: myAddressToUpdate,
        companies: companies.length ? companies : undefined,
        photo:
          profilePictureUri.slice(0, 4) === 'data'
            ? profilePictureUri
            : undefined,
      };

      console.log(dataToBeUpdated);

      return axiosInstance
        .put('/customer/edit', dataToBeUpdated)
        .then((res) => {
          console.log(res.status);
          console.log(JSON.stringify(res.data));
        })
        .catch((error) => {
          console.log('UPDATE CUSTOMER DATA ERROR: ', error);
          console.log('UPDATE CUSTOMER DATA ERROR: ', error.response.data);
        });
    };

    const sendFeedback = async (message: string) => {
      const feedback = { message };
      return axiosInstance
        .post('/customer/sendFeedback', feedback)
        .then((response) => console.log('Feedback Sent', response.data))
        .catch((error) =>
          console.log('ERROR IN SENDING FEEDBACK: ', error.response.data)
        );
    };

    const getAllTickets = async () => {
      return axiosInstance
        .get('/customer/tickets')
        .then((response) => {
          console.log('TICKETS RESPONSE:', response.data);
          return response.data;
        })
        .catch((error) => error.response.data);
    };

    const getTicketsByDate = async (startDate: number, endDate: number) => {
      return axiosInstance
        .get(
          `/customer/tickets/filter?endDate=${endDate}&startDate=${startDate}`
        )
        .then((response) => {
          console.log('TICKETS BY DATE RESPONSE:', response.data);
          return response.data;
        })
        .catch((error) => error.response.data);
    };

    const requestResetPassword = async (email: string) => {
      const data = email.toLowerCase();
      console.log(data);
      return axiosInstance
        .post('/customer/request-reset-password', { email: data })
        .then((response) => {
          console.log(response.status);
          return response.status;
        })
        .catch((error) => console.log(error));
    };

    const resetPassword = async (resetPasswordInputs: {
      password: string;
      repeatPassword: string;
      hash: string;
    }) => {
      return axiosInstance
        .post('/customer/reset-password', resetPasswordInputs)
        .then((response) => console.log(response.data))
        .catch((error) => console.log(error));
    };

    useEffect(() => {
      if (token && !customerInfo) {
        setIsLoading(true)
        getCustomerDataFromToken().then(()=> {setIsLoading(false)});
      }
    }, [token]);

    const providerValue = {
      customerInfo,
      isLoading,
      getTicketsByDate,
      getCustomerDataFromToken,
      updateCustomer,
      sendFeedback,
      getAllTickets,
      requestResetPassword,
      resetPassword,
      setCustomerInfo,
    };
    return (
      <CustomerContext.Provider value={providerValue}>
        {props.children}
      </CustomerContext.Provider>
    );
  };

export { CustomerContext, CustomerContextProvider };
