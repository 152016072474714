import { FormatedContact } from '../../interfaces';

const generateFullName = (contact: FormatedContact) => {
  let fullName = '';

  if (contact.prefix) {
    fullName += contact.prefix + ' ';
  }

  if (contact.myUserAttributes.firstName) {
    fullName += contact.myUserAttributes.firstName;
  }

  if (contact.phoneticMiddleName) {
    fullName += ' ' + contact.phoneticMiddleName;
  }

  if (contact.myUserAttributes.lastName) {
    fullName += ' ' + contact.myUserAttributes.lastName;
  }

  if (contact.sufix) {
    fullName += ' ' + contact.sufix;
  }

  if (!fullName) return contact.myCompanies[0].name;

  return fullName;
};

export default generateFullName;
