import React, {
  createContext,
  FunctionComponent,
  useState,
  useContext,
} from 'react';

import { AxiosContext } from './index';

import { CreditCardParams } from '../interfaces';

interface CreditCardContextProviderProps {
  children: React.ReactNode;
}

interface CreditCardContextState {
  creditCards: CreditCards;
  setCreditCards: React.Dispatch<React.SetStateAction<CreditCards>>;
  expiryDate: (creditCard: CreditCardParams) => string;
  formatExpiaryDateFromContact: (month: number, year: number) => string;
  // getTokenFromTheCard: (params: CreditCardParams) => Promise<any>;
  removeCard: (cardId: string) => Promise<void>;
  addNewCard: (cardId: string) => Promise<void>;
  changeDefaultCard: (cardId: string) => Promise<void>;
}

type CreditCards = CreditCardParams[];

const CreditCardContext = createContext<CreditCardContextState>(
  {} as CreditCardContextState
);

const CreditCardContextProvider: FunctionComponent<CreditCardContextProviderProps> =
  (props) => {
    const { axiosInstance } = useContext(AxiosContext);

    const [creditCards, setCreditCards] = useState<CreditCards>(
      [] as CreditCards
    );

    const expiryDate = (creditCard: CreditCardParams) => {
      const stringifiedYear = creditCard.expYear.toString();
      const stringifiedMonth = creditCard.expMonth.toString();

      if (creditCard.expMonth > 9) {
        return `${stringifiedMonth}/${stringifiedYear}`;
      } else {
        return `0${stringifiedMonth}/${stringifiedYear}`;
      }
    };

    const formatExpiaryDateFromContact = (month: number, year: number) => {
      const stringifiedYear = year.toString().slice(-2);
      const stringifiedMonth = month.toString();

      if (month > 9) {
        return `${stringifiedMonth}/${stringifiedYear}`;
      } else {
        return `0${stringifiedMonth}/${stringifiedYear}`;
      }
    };

    // const getTokenFromTheCard = async (params: CreditCardParams) => {
    //   return stripe.createTokenWithCard(params);
    // };

    const removeCard = async (cardId: string) => {
      return axiosInstance
        .delete('/customer/removeCard', { data: { cardId } })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => console.log(error));
    };

    const addNewCard = async (card: string) => {
      return axiosInstance
        .post('/customer/addCard', { card })
        .then((response) => console.log('NOVA KARTICA:', response.data))
        .catch((error) =>
          console.log('ERROR U DODAVANJU NOVE KARTICE:', error)
        );
    };

    const changeDefaultCard = async (cardId: string) => {
      console.log({ cardId });
      return axiosInstance
        .put('/customer/changeDefaultCard', { cardId })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => console.log(error.response.data));
    };

    const providerValue = {
      creditCards,
      setCreditCards,
      expiryDate,
      formatExpiaryDateFromContact,
      // getTokenFromTheCard,
      removeCard,
      addNewCard,
      changeDefaultCard,
    };

    return (
      <CreditCardContext.Provider value={providerValue}>
        {props.children}
      </CreditCardContext.Provider>
    );
  };

export { CreditCardContext, CreditCardContextProvider };
