import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';

interface IAuthenticationContext {
  token: string;
  saveUserToken: (token: string) => void;
  removeUserToken: () => void;
}

const AuthenticationContext = createContext({} as IAuthenticationContext);

interface AuthenticationContextProps {
  children: React.ReactNode;
}

const AuthenticationContextProvider: FunctionComponent<AuthenticationContextProps> =
  ({ children }) => {
    const [token, setToken] = useState('');

    const saveUserToken = (token: string) => {
      setToken(token);
      localStorage.setItem('@token', token);
    };

    const removeUserToken = () => {
      setToken('');
      localStorage.removeItem('@token');
    };

    const getUserToken = () => {
      let token = localStorage.getItem('@token');
      if (token) {
        setToken(token);
      }
    };

    useEffect(() => {
      if (!token) {
        getUserToken();
      }
      console.log('TOKEN', token);
    }, [token]);

    const providerValue = {
      token,
      saveUserToken,
      removeUserToken,
    };

    return (
      <AuthenticationContext.Provider value={providerValue}>
        {children}
      </AuthenticationContext.Provider>
    );
  };

export { AuthenticationContext, AuthenticationContextProvider };
