export enum DashboardTextEnum {
  MYPROFILE = 'My Profile',
  CONTACTS = 'Contacts',
  TICKETS = 'Tickets',
  SUBSCRIPTION_PLAN = 'Subscription Plan',
  QUESTIONS = 'Questions',
  CALL725CONTACT = 'Call 725-contact',
  CREDITCARDS = 'My Profile / Credit Cards',
}

export enum DashboardLinkEnum {
  CONTACTS = '/',
  TICKETS = '/tickets',
  SUBSCRIPTION_PLAN = '/subscription-plan',
  QUESTIONS = '/questions',
  CALL725CONTACT = '/call-725-contact',
  MYPROFILE = '/my-profile',
  CREDITCARDS = '/credit-cards',
}
