import React, { FunctionComponent } from 'react';

import logo from '../../../assets/yes_logo_small.png';

interface PlanChooserProps {
  price: number;
  priceFlag: string;
  isPressed: boolean;
  disabled?: boolean;
  clickHandler: React.MouseEventHandler<HTMLButtonElement>;
}
const PlanChooser: FunctionComponent<PlanChooserProps> = ({
  price,
  priceFlag,
  disabled,
  isPressed,
  clickHandler,
}) => {
  let buttonClassName;

  if (isPressed) {
    buttonClassName = 'circle__button__full';
  } else if (disabled) {
    buttonClassName = 'circle__button__grey';
  } else {
    buttonClassName = 'circle__button';
  }

  return (
    <div className="plan__chooser__wrapper">
      <button
        className={buttonClassName}
        onClick={clickHandler}
        disabled={disabled}
      >
        {disabled ? null : (
          <img src={logo} alt="logo" className="button_logo" />
        )}
      </button>
      <p className="plan__price">
        ${price} / {priceFlag}
      </p>
    </div>
  );
};

export default PlanChooser;
