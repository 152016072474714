import React, { FunctionComponent, useContext } from 'react';

import './RegistrationTemplate.scss';

import { GlobalStateContext } from '../../contexts';
import { ActivityIndicatorModal } from '../../components';

import logo from '../../assets/logo.png';
import { backgroundAuthText } from '../../util/appText';

interface RegistrationTemplateProps {
  children: React.ReactNode;
}

const RegistrationTemplate: FunctionComponent<RegistrationTemplateProps> = ({
  children,
}) => {
  const { showActivityIndicatorModal } = useContext(GlobalStateContext);

  return (
    <div className="page__wrapper">
      <div className="background__image__wrapper">
        <div className="background__image__content">
          <img src={logo} alt="logo" className="logo" />
          <p className="phrase__text">{backgroundAuthText}</p>
        </div>
      </div>
      <div className="content__wrapper">{children}</div>
      <ActivityIndicatorModal
        showActivityIndicatorModal={showActivityIndicatorModal}
      />
    </div>
  );
};

export default RegistrationTemplate;
