import React, { FunctionComponent, useEffect, useContext } from 'react';

import { CustomerContext } from '../../../contexts';

import {
  Subscription,
  SelectedSubscriptionMain,
  SubscriptionPeriod,
} from '../../../interfaces';

import { SubscriptionTextMain, RegularButton, PlanChooser } from '../../index';

import isSubscriptionSelectionDisabled from '../../../util/functions/isSubscriptionSelectionDisabled';

interface SubscriptionMainCardProps {
  subscriptionType: Subscription;
  selectedSubscription: SelectedSubscriptionMain;
  index: number;
  numberOfSubscriptions: number;
  setSelectedSubscription: React.Dispatch<
    React.SetStateAction<SelectedSubscriptionMain>
  >;
  upgradeSubscription: () => void;
}

const SubscriptionMainCard: FunctionComponent<SubscriptionMainCardProps> = ({
  subscriptionType,
  selectedSubscription,
  index,
  numberOfSubscriptions,
  setSelectedSubscription,
  upgradeSubscription,
}) => {
  const { customerInfo } = useContext(CustomerContext);

  const { id, monthlyPrice, yearlyPrice } = subscriptionType;

  const customerSubscriptionId =
    customerInfo!.subscription.subscriptionAttributes.id;

  // let wrapperClassName = 'subscription__main__wrapper';
  // if (customerSubscriptionId === id && id === 1) {
  //   wrapperClassName = 'subscription__main__wrapper__with__background1';
  // } else if (customerSubscriptionId === id && id === 2) {
  //   wrapperClassName = 'subscription__main__wrapper__with__background2';
  // } else if (customerSubscriptionId === id && id === 3) {
  //   wrapperClassName = 'subscription__main__wrapper__with__background3';
  // }

  const getWrapperClassName = () => {
    if (numberOfSubscriptions === 3) {
      if (index === 0 && customerSubscriptionId === id) {
        return 'subscription__main__wrapper__with__background1';
      }

      if (index === 1 && customerSubscriptionId === id) {
        return 'subscription__main__wrapper__with__background3';
      }

      if (index === 2 && customerSubscriptionId === id) {
        return 'subscription__main__wrapper__with__background5';
      }
    }

    if (numberOfSubscriptions === 4) {
      if (index === 0 && customerSubscriptionId === id) {
        return 'subscription__main__wrapper__with__background1';
      }

      if (index === 1 && customerSubscriptionId === id) {
        return 'subscription__main__wrapper__with__background2';
      }

      if (index === 2 && customerSubscriptionId === id) {
        return 'subscription__main__wrapper__with__background3';
      }

      if (index === 3 && customerSubscriptionId === id) {
        return 'subscription__main__wrapper__with__background5';
      }
    }

    if (numberOfSubscriptions === 5) {
      if (index === 0 && customerSubscriptionId === id) {
        return 'subscription__main__wrapper__with__background1';
      }

      if (index === 1 && customerSubscriptionId === id) {
        return 'subscription__main__wrapper__with__background2';
      }

      if (index === 2 && customerSubscriptionId === id) {
        return 'subscription__main__wrapper__with__background3';
      }

      if (index === 3 && customerSubscriptionId === id) {
        return 'subscription__main__wrapper__with__background4';
      }

      if (index === 4 && customerSubscriptionId === id) {
        return 'subscription__main__wrapper__with__background5';
      }
    }

    return 'subscription__main__wrapper';
  };

  const handlePurchaseButton = () => {
    upgradeSubscription();
  };

  const onSubscriptionSelect = (type: SubscriptionPeriod) => {
    let subscription = {
      id: id,
      monthly: false,
      year: false,
    };

    if (type === 'monthly' && subscription.monthly) {
      subscription.monthly = false;
    } else if (type === 'year' && subscription.year) {
      subscription.year = false;
    } else if (type === 'monthly') {
      subscription.monthly = true;
    } else if (type === 'year') {
      subscription.year = true;
    }

    setSelectedSubscription(subscription);
  };

  const isSubscriptionSelcted = (type: SubscriptionPeriod) => {
    if (id === selectedSubscription.id) {
      if (type === 'monthly' && selectedSubscription.monthly) {
        return true;
      } else if (type === 'year' && selectedSubscription.year) {
        return true;
      }

      return false;
    }

    return false;
  };

  const isDisabledMonthly = isSubscriptionSelectionDisabled(
    customerInfo!.subscription,
    subscriptionType,
    true
  );

  const isDisabledYearly = isSubscriptionSelectionDisabled(
    customerInfo!.subscription,
    subscriptionType,
    false
  );

  const isButtonDisabled =
    (isDisabledYearly && isDisabledMonthly) ||
    (!isSubscriptionSelcted('monthly') && !isSubscriptionSelcted('year'));

  return (
    <div className={getWrapperClassName()}>
      <div className="subscription__card__inner__wrapper">
        <p className="subscription__type__text">{subscriptionType.name}</p>
        <div className="subscriptiontext__wrapper">
          <SubscriptionTextMain subscriptionType={subscriptionType} />
        </div>
        <div className="choose__subscription__wrapper">
          <PlanChooser
            disabled={isDisabledMonthly}
            price={monthlyPrice}
            priceFlag="monthly"
            clickHandler={() => onSubscriptionSelect('monthly')}
            isPressed={isSubscriptionSelcted('monthly')}
          />
          <div className="separator" />
          <PlanChooser
            disabled={isDisabledYearly}
            price={yearlyPrice}
            priceFlag="year"
            clickHandler={() => onSubscriptionSelect('year')}
            isPressed={isSubscriptionSelcted('year')}
          />

          <div className="button_wrapper">
            {customerSubscriptionId === id ? (
              <p className="current_plan__text">Your current plan</p>
            ) : (
              <RegularButton
                disabled={isButtonDisabled}
                type="greyWhenDisabled"
                text="Purchase"
                onClick={handlePurchaseButton}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionMainCard;
