import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { SubscriptionContext } from '../../../contexts';

import { RegistrationLinkEnum } from '../../../util/enums';

import {
  RegistrationHeader,
  FrequencySwitch,
  SubscriptionAuthCard,
} from '../../../components';

const SignUpChoosePlanPage = () => {
  const { subscriptions, formatSubscription } = useContext(SubscriptionContext);

  const [isMonthlyClicked, setIsMonthlyClicked] = useState(false);
  const [chosenSubscriptionId, setChosenSubscriptionId] = useState(2);

  const history = useHistory();

  const navigateBackwards = () => {
    history.goBack();
  };

  const navigateToPaymentType = () => {
    history.push(RegistrationLinkEnum.SIGNUPAYMENTTYPE);
  };

  const handleNextButton = () => {
    formatSubscription({
      isMonthly: isMonthlyClicked,
      id: chosenSubscriptionId,
    });
    navigateToPaymentType();
  };

  return (
    <div className="signup__choose__plan__page__wrapper">
      <RegistrationHeader
        onClickBack={navigateBackwards}
        onClickNext={handleNextButton}
        text="Choose plan"
        isButtonDisabled={false}
      />
      <div className="frequencyswitch__wrapper">
        <FrequencySwitch
          textLeft="Annually"
          textRight="Monthly"
          isRightClicked={isMonthlyClicked}
          setIsRightClicked={setIsMonthlyClicked}
        />
      </div>
      {subscriptions.length && (
        <div className="subscriptioncard__wrapper">
          {subscriptions.sort((a, b) => a.yearlyPrice - b.yearlyPrice)
          .map((subscription, index) => (
            <SubscriptionAuthCard
              key={index}
              index={index}
              numberOfSubscriptions={subscriptions.length}
              subscriptionType={subscription}
              isMonthly={isMonthlyClicked}
              chosenSubscriptionId={chosenSubscriptionId}
              setChosenSubscriptionId={setChosenSubscriptionId}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SignUpChoosePlanPage;
