import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { CustomerContext, GlobalStateContext } from '../../../contexts';

import { AuthInput, RegularButton, BackArrowButton } from '../../../components';

import { RegistrationLinkEnum } from '../../../util/enums';

const ResetPasswordPage = () => {
  const { requestResetPassword } = useContext(CustomerContext);
  const { setShowActivityIndicatorModal } = useContext(GlobalStateContext);

  const [emailData, setEmailData] = useState('');
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

  const validateEmail = () => {
    if (!emailData) {
      setIsErrorVisible(true);
    } else if (regexEmail.test(String(emailData)) === false && emailData) {
      setIsErrorVisible(true);
    } else if (regexEmail.test(String(emailData)) === true) {
      setIsErrorVisible(false);
    }
  };

  const history = useHistory();

  const navigateBackwards = () => {
    history.goBack();
  };

  const navigateToTheNextPage = () => {
    history.push(RegistrationLinkEnum.RESETPASSWORD2);
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailData(event.target.value);
  };

  const onFocus = () => {
    setIsErrorVisible(false);
  };

  const handleRecoverPasswordButton = () => {
    if (!emailData) {
      return setIsErrorVisible(true);
    }
    if (!isErrorVisible) {
      setShowActivityIndicatorModal(true);
      requestResetPassword(emailData).then((response) => {
        setShowActivityIndicatorModal(false);
        if (response !== 200) return setIsErrorVisible(true);

        return navigateToTheNextPage();
      });
    }
  };

  return (
    <div className="reset__password__page__wrapper">
      <BackArrowButton onClick={navigateBackwards} />
      <div className="inner__wrapper">
        <p className="reset__password__headline">Reset Password</p>
        <p className="reset__password__text">
          Please enter your email so we can restore your account.
        </p>

        <AuthInput
          value={emailData}
          onChange={handleInput}
          onBlur={validateEmail}
          placeholder="E-mail*"
          textColor="black"
          errorText="Please enter correct e-mail"
          isErrorVisible={isErrorVisible}
          onFocus={onFocus}
        />

        <div className="button__wrapper">
          <RegularButton
            text="Recover password"
            type="black"
            onClick={handleRecoverPasswordButton}
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
