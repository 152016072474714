import React, { FunctionComponent } from 'react';
import './CreditCardFront.scss';

import masterCardLogo from '../../../assets/masterCardLogo.png';
import visaLogo from '../../../assets/visaLogo.png';

interface CreditCardFrontProps {
  brand?: string;
  name?: string;
  exp_month?: number;
  exp_year?: number;
  last4?: string;
  defaultCard?: boolean;
}

const CreditCardFront: FunctionComponent<CreditCardFrontProps> = ({
  brand,
  name,
  exp_month,
  exp_year,
  last4,
  defaultCard,
}) => {
  const expirationDate =
    exp_month && exp_year ? `${exp_month}/${exp_year}` : 'MM/YYYY';

  return (
    <div className="creditCard__front">
      <div className="creditcard__inner__wrapper">
        <div className="creditcard__logo_default_wrapper">
          <div className="creditcard__logo__wrapper">
            {brand &&
              (brand === 'mastercard' ? (
                <img
                  src={masterCardLogo}
                  alt="masterCard"
                  className="masterCard"
                />
              ) : (
                <img src={visaLogo} alt="visa" className="visaCard" />
              ))}
          </div>
          {defaultCard && <p className="default_card_text">Default Card</p>}
        </div>
        {last4 ? (
          <p className="creditcard__number__text">**** **** **** {last4}</p>
        ) : (
          <p className="creditcard__number__text">---- ---- ---- ----</p>
        )}
        <div className="info__wrapper">
          <div className="cardholder__wrapper">
            <p>Card Holder</p>
            <p>{name}</p>
          </div>
          <div className="exiprydate__wrapper">
            <p>Expires</p>
            <p>{expirationDate}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditCardFront;
