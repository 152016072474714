import React, { FunctionComponent } from 'react';
import './InfoLabel.scss';

interface InfoLabelProps {
  index?: number;
  label: string;
  value: string;
}

const InfoLabel: FunctionComponent<InfoLabelProps> = ({
  index,
  value,
  label,
}) => {
  return (
    <div className="contact__info__label__wrapper">
      <div className="contact__info__label__inner_wrapper">
        <p className="label__name__text">
          {label} {index ? index + 1 : ''}
        </p>
        <p className="value_text">{value}</p>
      </div>
    </div>
  );
};

export default InfoLabel;
