import React, {
  FunctionComponent,
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';

// import {
//   GoogleSignin,
//   statusCodes,
// } from '@react-native-google-signin/google-signin';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import { AxiosContext } from './AxiosContext';
import { AuthenticationContext } from './AuthenticationContext';

// import {WEB_CLIENT_ID} from '../../config';

interface RegistrationUser {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  backupPhoneNumber: string;
  agentId: string;
}

interface LoginUser {
  email: string;
  password: string;
}

interface TwoStepLoginData {
  userId: number;
  securityQuestionId: number;
  passphrase: string;
}

type RegistrationInputs =
  | 'email'
  | 'password'
  | 'firstName'
  | 'lastName'
  | 'phoneNumber'
  | 'backupPhoneNumber'
  | 'agentId';

interface LoginContextState {
  userLogin: (loginData: LoginUser) => Promise<any>;
  twoStepLogin: (data: TwoStepLoginData) => Promise<any>;
  // facebookAuth: () => Promise<string | undefined>;
  // googleAuth: () => Promise<string | null | undefined>;
  registerUser: () => Promise<number | void>;
  handleRegistrationInputs: (
    filedName: RegistrationInputs,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  oAuth: (token: string, service: number) => Promise<any>;
  // createPhoneNumberForOAuth: () => Promise<void>;
  registerData: RegistrationUser;
}

interface LoginContextProviderProps {
  children: React.ReactNode;
}

const LoginContext = createContext({} as LoginContextState);

const LoginContextProvider: FunctionComponent<LoginContextProviderProps> = ({
  children,
}) => {
  const initialRegisterData = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    backupPhoneNumber: '',
    agentId: '',
  };
  const [registerData, setRegisterData] =
    useState<RegistrationUser>(initialRegisterData);

  const { axiosInstance } = useContext(AxiosContext);
  const { saveUserToken } = useContext(AuthenticationContext);

  const handleRegistrationInputs = (
    filedName: RegistrationInputs,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRegisterData({
      ...registerData,
      [filedName]: event.target.value,
    });
  };

  const userLogin = async (loginData: LoginUser) => {
    const data = { ...loginData, email: loginData.email.toLowerCase().trim() };

    return axiosInstance
      .post('/customer/login', data)
      .then((response) => {
        console.log(response.data);

        if (response.data.token) {
          saveUserToken(response.data.token);
        }

        return response;
      })
      .catch((error) => console.log(error));
  };

  const twoStepLogin = async (data: TwoStepLoginData) => {
    return axiosInstance
      .post('/customer/loginTwoSteps', data)
      .then((response) => {
        if (response.status === 200) {
          saveUserToken(response.data.token);
        }
        console.log(response.data);
        return response;
      })
      .catch((error) => console.log(error));
  };

  const registerUser = () => {
    const data = {
      ...registerData,
      email: registerData.email.toLowerCase().trim(),
      backupPhoneNumber:
        registerData.backupPhoneNumber === ''
          ? undefined
          : registerData.backupPhoneNumber,
    };

    return axiosInstance
      .post('/customer/register', data)
      .then((response) => {
        console.log(response.status);
        console.log('TOKEN PRI REGISTRACIJI: ', response.data);
        if (response.status === 200) {
          saveUserToken(response.data.token);
        }
        return response.status;
      })
      .catch((error) => {
        console.log('Register Error: ', error.message);
      });
  };

  // const facebookAuth = async () => {
  //   try {
  //     const loginResult = await LoginManager.logInWithPermissions([
  //       'public_profile',
  //       'email',
  //     ]);
  //     if (loginResult.isCancelled) {
  //       console.log('Login cancelled');
  //     } else {
  //       console.log(loginResult);
  //     }
  //     const data = await AccessToken.getCurrentAccessToken();
  //     console.log(data?.accessToken);
  //     return data?.accessToken;
  //   } catch (error) {
  //     console.log('FACEBOOK LOGIN ERROR: ', error);
  //   }
  // };

  // const googleAuth = async () => {
  //   try {
  //     await GoogleSignin.hasPlayServices({
  //       showPlayServicesUpdateDialog: true,
  //     });
  //     const userInfo = await GoogleSignin.signIn();
  //     console.log(userInfo);
  //     return userInfo.idToken;
  //   } catch (error) {
  //     if (error.code === statusCodes.SIGN_IN_CANCELLED) {
  //       // user cancelled the login flow
  //     } else if (error.code === statusCodes.IN_PROGRESS) {
  //       // operation (e.g. sign in) is in progress already
  //     } else if (error.code === statusCodes.PLAY_SERVICES_NOT_AVAILABLE) {
  //       // play services not available or outdated
  //     } else {
  //       // some other error happened
  //     }
  //   }
  // };

  const oAuth = async (token: string, service: number) => {
    const data = { token, service };
    console.log(data);

    return axiosInstance
      .post('/customer/OAuth', data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  };

  // const createPhoneNumberForOAuth = async () => {
  //   const phoneData = {
  //     primaryPhoneNumber: registerData.phoneNumber,
  //     secondaryPhoneNumber: registerData.backupPhoneNumber,
  //   };

  //   return axiosInstance
  //     .post('/phoneNumber/create', phoneData)
  //     .then((response) => console.log(response.data))
  //     .catch((error) => console.log(error));
  // };

  // useEffect(() => {
  //   GoogleSignin.configure({
  //     webClientId: WEB_CLIENT_ID,
  //   });
  // }, []);

  const providerValue = {
    registerData,
    userLogin,
    twoStepLogin,
    registerUser,
    handleRegistrationInputs,
    // facebookAuth,
    // googleAuth,
    oAuth,
    // createPhoneNumberForOAuth,
  };

  return (
    <LoginContext.Provider value={providerValue}>
      {children}
    </LoginContext.Provider>
  );
};

export { LoginContext, LoginContextProvider };
