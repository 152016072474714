import React, { FunctionComponent, useState, useRef, useContext } from 'react';

import { ActivityIndicatorModal } from '../..';

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import {
  GlobalStateContext,
  CreditCardContext,
  CustomerContext,
} from '../../../contexts';

import { RegularButton } from '../..';
import { CreditCard } from '../../../interfaces';

import './CreditCardForm.scss';

interface CreditCardFormProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCreditCard?: React.Dispatch<React.SetStateAction<CreditCard>>;
}

const CreditCardForm: FunctionComponent<CreditCardFormProps> = ({
  setShowModal,
  setCreditCard,
}) => {
  const { addNewCard } = useContext(CreditCardContext);
  const { getCustomerDataFromToken } = useContext(CustomerContext);

  const [creditCardData, setCreditCardData] = useState({
    cardHolder: '',
  });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const cardRef = useRef<any>();

  const inputHandler: Function = (
    fieldName: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCreditCardData({
      ...creditCardData,
      [fieldName]: event.target.value,
    });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleContinueCreditCard = () => {
    setIsModalVisible(true);
    if (stripe && elements) {
      const cardNumberElement = elements.getElement(CardNumberElement);

      if (cardNumberElement) {
        stripe
          .createToken(cardNumberElement, {
            name: creditCardData.cardHolder,
          })
          .then((value) => {
            if (value.error) {
              alert(value.error.message);
              setIsModalVisible(false);
              return;
            } else {
              console.log(value.token);
              const name = value.token.card?.name ? value.token.card.name : '';
              const brand = value.token.card?.brand
                ? value.token.card.brand
                : '';
              const exp_month = value.token.card?.exp_month
                ? value.token.card.exp_month
                : 1;
              const exp_year = value.token.card?.exp_year
                ? value.token.card.exp_year
                : 1;

              const last4 = value.token.card?.last4
                ? value.token.card?.last4
                : '';

              const token = value.token.id;
              console.log({ name, brand, exp_month, exp_year, token });
              if (setCreditCard) {
                setCreditCard({
                  name,
                  brand,
                  exp_month,
                  exp_year,
                  token,
                  last4,
                });
                setIsModalVisible(false);
                closeModal();
              } else {
                addNewCard(token)
                  .then(() => {
                    getCustomerDataFromToken();
                  })
                  .then(() => setIsModalVisible(false))
                  .then(() => {
                    closeModal();
                  });
              }
            }
          });
      }
    }
  };

  return (
    <div className="form__wrapper">
      <div className="inputs__wrapper">
        <CardNumberElement
          className="creditcard__stripe__card_number"
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#2B2E30',
                backgroundColor: 'transparent',
                letterSpacing: '0.3em',
                '::placeholder': {
                  fontSize: '16px',
                  color: 'rgba(134, 154, 165, 1)',
                  fontWeight: 100,
                  letterSpacing: '0.3em',
                },
              },
            },
            placeholder: '----  ----  ----  ----',
          }}
        />
        <input
          type="text"
          placeholder="Card Holder"
          value={creditCardData.cardHolder}
          onChange={(e) => inputHandler('cardHolder', e)}
        />
        <CardExpiryElement
          className="creditcard__stripe__card_exp_cvc"
          options={{
            style: {
              base: {
                fontSize: '16px',

                color: '#2B2E30',
                backgroundColor: 'transparent',
                '::placeholder': {
                  fontSize: '16px',
                  color: 'rgba(134, 154, 165, 1)',
                  fontWeight: '100',
                },
              },
            },
            placeholder: 'MM / YY',
          }}
        />
        <CardCvcElement
          className="creditcard__stripe__card_exp_cvc"
          options={{
            style: {
              base: {
                fontSize: '16px',

                color: '#2B2E30',
                backgroundColor: 'transparent',
                '::placeholder': {
                  fontSize: '16px',
                  color: 'rgba(134, 154, 165, 1)',
                  fontWeight: '100',
                },
              },
            },
            placeholder: 'Security Code',
          }}
        />
      </div>
      <div className="buttons__wrapper">
        <div className="button__wrapper">
          <RegularButton
            type="black"
            text="Add"
            onClick={handleContinueCreditCard}
          />
        </div>

        <RegularButton type="darkGrey" text="Cancel" onClick={closeModal} />
      </div>
      <ActivityIndicatorModal showActivityIndicatorModal={isModalVisible} />
    </div>
  );
};

export default CreditCardForm;
