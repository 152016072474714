export enum RegistrationLinkEnum {
  LOGIN = '/',
  SIGNUPMAIN = '/signup-main',
  SIGNUPNAMENUMBER = '/signup-name-number',
  SIGNUPPASSPHRASE = '/signup-passphrase',
  SIGNUPCHOOSEPLAN = '/signup-chooseplan',
  SIGNUPAYMENTTYPE = '/signup-paymenttype',
  RESETPASSWORD = '/resetpassword',
  RESETPASSWORD2 = '/resetpassword-sent-mail',
  RESETPASSWORD3 = '/resetpassword-type-code',
}
