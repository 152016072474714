import React, { FunctionComponent } from 'react';
import './CloseButton.scss';

import removeButton from '../../../assets/removeButton.png';

interface CloseButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const CloseButton: FunctionComponent<CloseButtonProps> = ({ onClick }) => {
  return (
    <button className="remove__button__button" onClick={onClick}>
      <img
        src={removeButton}
        alt="remove_button"
        className="remove__butoon__image"
      />
    </button>
  );
};

export default CloseButton;
