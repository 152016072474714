import React, { createContext, FunctionComponent, useContext } from 'react';
import axios, { AxiosInstance } from 'axios';
import { AuthenticationContext } from './AuthenticationContext';

const devUrl = 'http://localhost:3000/api/';
const deployUrl = 'https://1eocnxysy2.execute-api.us-west-2.amazonaws.com/api';
const test = 'https://30c2-46-240-142-62.ngrok.io/api/';
const ngrok = 'https://7913-46-240-142-62.ngrok.io/';

interface IAxiosContext {
  axiosInstance: AxiosInstance;
}

const AxiosContext = createContext({} as IAxiosContext);

interface AxiosContextProps {
  children: React.ReactNode;
}

const AxiosContextProvider: FunctionComponent<AxiosContextProps> = ({
  children,
}) => {
  const { token } = useContext(AuthenticationContext);

  const axiosInstance = axios.create({
    baseURL: deployUrl,
    headers: {
      Authorization: 'Bearer '.concat(token),
      'Content-Type': 'application/json',
    },
    validateStatus: () => true,
  });

  const providerValue = {
    axiosInstance,
  };

  return (
    <AxiosContext.Provider value={providerValue}>
      {children}
    </AxiosContext.Provider>
  );
};

export { AxiosContext, AxiosContextProvider };
