import React, { useState, useContext, useEffect } from 'react';

import { CustomerContext } from '../../../contexts';

import {
  MainHeader,
  CreditCardFront,
  AddCreditCard,
  CreditCardModal,
  CreditCardMakeDefaultModal,
} from '../../../components';

import { CreditCard } from '../../../interfaces';

import { DashboardTextEnum } from '../../../util/enums';

const CreditCardsPage = () => {
  const { customerInfo } = useContext(CustomerContext);

  const [isCreditCardModalOpen, setIsCreditCardModalOpen] = useState(false);
  const [
    isCreditCardMakeDefaultModalOpen,
    setIsCreditCardMakeDefaultModalOpen,
  ] = useState(false);
  const [creditCards, setCreditCards] = useState(customerInfo?.creditCards);
  const [creditCardIndex, setCreditCardIndex] = useState(0);

  const openCreditCardModal = () => {
    setIsCreditCardModalOpen(true);
  };

  const openCreditCardMakeDefaultModal = (index: number) => {
    setCreditCardIndex(index);
    setIsCreditCardMakeDefaultModalOpen(true);
  };

  useEffect(() => {
    setCreditCards(customerInfo?.creditCards);
  }, [customerInfo]);

  return (
    <div className="creditcards_page__wrapper">
      <MainHeader title={DashboardTextEnum.CREDITCARDS} />

      <div className="credit_cards__wrapper">
        {creditCards &&
          creditCards.map((creditCard, index) => (
            <div
              key={index}
              className="credit_card__wrapper"
              onClick={() => openCreditCardMakeDefaultModal(index)}
            >
              <CreditCardFront
                brand={creditCard.brand}
                exp_month={creditCard.exp_month}
                exp_year={creditCard.exp_year}
                last4={creditCard.last4}
                defaultCard={creditCard ? creditCard.default : false}
                name={creditCard.cardHolder}
              />
            </div>
          ))}

        <AddCreditCard onClick={openCreditCardModal} />
        <CreditCardModal
          showModal={isCreditCardModalOpen}
          setShowModal={setIsCreditCardModalOpen}
        />
        <CreditCardMakeDefaultModal
          creditCardIndex={creditCardIndex}
          isModalOpen={isCreditCardMakeDefaultModalOpen}
          setIsModalOpen={setIsCreditCardMakeDefaultModalOpen}
        />
      </div>
    </div>
  );
};

export default CreditCardsPage;
