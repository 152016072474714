import React, { FunctionComponent, useState, useContext } from 'react';
import Modal from 'react-modal';

import { CustomerContext, CreditCardContext } from '../../../contexts';

import {
  CreditCardFront,
  RegularButton,
  CloseButton,
  ActivityIndicatorModal,
} from '../../index';

interface CreditCardMakeDefaultModalProps {
  isModalOpen: boolean;
  creditCardIndex: number;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreditCardMakeDefaultModal: FunctionComponent<CreditCardMakeDefaultModalProps> =
  ({ isModalOpen, creditCardIndex, setIsModalOpen }) => {
    const { formatExpiaryDateFromContact, removeCard, changeDefaultCard } =
      useContext(CreditCardContext);
    const { customerInfo, getCustomerDataFromToken } =
      useContext(CustomerContext);

    const [isLoading, setIsloading] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);

    const creditCard = customerInfo!.creditCards[creditCardIndex];

    const closeModal = () => {
      setIsModalOpen(false);
      setIsErrorVisible(false);
    };

    const deleteCard = (id: string) => {
      if (creditCard.default) {
        setIsErrorVisible(true);
      } else {
        setIsloading(true);
        removeCard(id)
          .then(() => {
            getCustomerDataFromToken();
          })
          .then(() => setIsloading(false))
          .then(() => setIsModalOpen(false));
      }
    };

    const makeDefaultCard = (id: string) => {
      setIsloading(true);
      changeDefaultCard(id)
        .then(() => setIsloading(false))
        .then(() => {
          getCustomerDataFromToken();
        })
        .then(() => {
          setIsModalOpen(false);
        });
    };

    const errorMessageClass = isErrorVisible
      ? 'error_message'
      : 'error_message_not_visible';

    return (
      <Modal
        isOpen={isModalOpen}
        ariaHideApp={false}
        className="credit__card__make__default__modal"
        overlayClassName="modal__overlay"
      >
        <div className="modal__header">
          <div className="modal__header__text__wrapper">
            <p className="modal__header__text">Card Info</p>
          </div>
          <div className="close__button__wrapper">
            <CloseButton onClick={closeModal} />
          </div>
        </div>

        <div className="inner__wrapper">
          <CreditCardFront
            brand={creditCard.brand}
            name="Djordje SImovic"
            exp_month={creditCard.exp_month}
            exp_year={creditCard.exp_year}
            last4={creditCard.last4}
            defaultCard={creditCard ? creditCard.default : false}
          />
          <div className="error_message_wrapper">
            <p className={errorMessageClass}>You cannot delete Default Card</p>
          </div>
          <div className="buttons_wrapper">
            <div className="button_wrapper">
              <RegularButton
                type="greyWhenDisabled"
                text="Make Default"
                onClick={() => {
                  makeDefaultCard(creditCard.id);
                }}
                disabled={creditCard ? creditCard.default : false}
              />
            </div>
            <RegularButton
              type="red"
              text="Delete"
              onClick={() => deleteCard(creditCard.id)}
            />
          </div>
        </div>
        <ActivityIndicatorModal showActivityIndicatorModal={isLoading} />
      </Modal>
    );
  };

export default CreditCardMakeDefaultModal;
