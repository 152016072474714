import React, { useState, useContext } from 'react';

import { CustomerContext, GlobalStateContext } from '../../../contexts';

import { RegularButton, MainHeader } from '../../../components';

import { webLink } from '../../../util/links';

import { DashboardTextEnum } from '../../../util/enums';
import CallModal from '../../../components/modals/CallModal/CallModal';

const QuestionsPage = () => {
  const { sendFeedback } = useContext(CustomerContext);
  const { setShowActivityIndicatorModal } = useContext(GlobalStateContext);

  const [inputData, setInputData] = useState('');
  const [isFeedbackSent, setFeedbackSent] = useState(false);
  const [showCallingModal, setShowCallingModal] = useState(false);
  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputData(e.target.value);
  };

  const handleSendButton = () => {
    if (inputData) {
      setShowActivityIndicatorModal(true);
      sendFeedback(inputData)
        .then(() => {
          setShowActivityIndicatorModal(false);
          setFeedbackSent(true);
        })
        .catch((error) => {
          console.log(error);
          setShowActivityIndicatorModal(false);
        });
    }
  };

  return (
    <div className="questionpage__wrapper">
      <MainHeader title={DashboardTextEnum.QUESTIONS} />
      <div className="questionpage__inner__wrapper">
        <div className="first_part">
          <div className="text__wrapper">
            <p>Concierge support can be initiated by phone, or through:</p>
            <a href={webLink} target="_blank" rel="noreferrer">
              <b>
                <u>{webLink}</u>
              </b>
            </a>
          </div>
        </div>

        <div className="second_part">
          <div className="second_part_text_wrapper">
            <p>
              Sales and Customer Service:
              <br />
              1-725-CONTACT (1-725-266-8228)
            </p>
            <p>Have any questions or feedback? Let us know.</p>
          </div>
          <CallModal
            showModal={showCallingModal}
            setShowModal={setShowCallingModal}
          />
          <div className="button_wrapper">
            <RegularButton
              type="black"
              text="Call 1-725-CONTACT"
              onClick={() => setShowCallingModal(!showCallingModal)}
            />
          </div>
        </div>

        {isFeedbackSent ? (
          <p className="feedback_sent__text">
            <b>
              Thank you! We strive to respond to general inquiries within 48
              hours.
            </b>
          </p>
        ) : (
          <div className="third_part">
            <p>Have any questions or feedback? Let us know.</p>
            <textarea
              value={inputData}
              placeholder="Write a message to 725contact"
              onChange={handleInput}
              rows={5}
              cols={100}
              className="message__input"
            ></textarea>
            <div className="button_wrapper">
              <RegularButton
                type="black"
                text="Send"
                onClick={handleSendButton}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionsPage;
