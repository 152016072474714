import React, { FunctionComponent } from 'react';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import AppleLogin from 'react-apple-login';

import appleLogo from '../../../assets/appleLogo.png';
import facebookLogo from '../../../assets/facebookLogo.png';
import googleLogo from '../../../assets/googleLogo.png';

interface SocialMediaButtonsProps {
  responseFacebook: (response: any) => void;
  onFailureGoogle: (response: any) => void;
  responseGoogle: (response: any) => void;
  responseApple: (response: any) => void;
}

const SocialMediaButtons: FunctionComponent<SocialMediaButtonsProps> = ({
  responseFacebook,
  onFailureGoogle,
  responseGoogle,
  responseApple,
}) => {
  return (
    <div className="social_buttons_wrapper">
      <GoogleLogin
        clientId="367489585096-15h3utr04tt408pblqa9unk045qgk790.apps.googleusercontent.com"
        render={(renderProps) => (
          <button className="social_button" onClick={renderProps.onClick}>
            <img
              src={googleLogo}
              alt="googleLogo"
              className="google_logo_img"
            />
          </button>
        )}
        onSuccess={responseGoogle}
        onFailure={onFailureGoogle}
      />
      <FacebookLogin
        appId="498024361289217"
        callback={responseFacebook}
        fields="name,email,picture"
        render={(renderProps: any) => (
          <button className="social_button" onClick={renderProps.onClick}>
            <img
              src={facebookLogo}
              alt="facebookLogo"
              className="facebook_logo_img"
            />
          </button>
        )}
      />
      <AppleLogin
        clientId="com.sigex.app"
        callback={responseApple}
        redirectURI="https://redirectUrl.com"
        render={(renderProps: any) => (
          <button className="social_button" onClick={renderProps.onClick}>
            <img src={appleLogo} alt="appleLogo" className="apple_logo_img" />
          </button>
        )}
      />
    </div>
  );
};

export default SocialMediaButtons;
