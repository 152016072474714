import React, { FunctionComponent, useContext } from 'react';
import Modal from 'react-modal';
import { format } from 'date-fns';

import { CustomerContext } from '../../../contexts';

import { CloseButton } from '../../index';

import { ITicket } from '../../../interfaces';
import { TicketStatus } from '../../../util/enums';

interface TicketModalProps {
  allTickets: ITicket[];
  showModal: boolean;
  index: number;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const TicketModal: FunctionComponent<TicketModalProps> = ({
  showModal,
  index,
  allTickets,
  setShowModal,
}) => {
  // const { allTickets } = useContext(CustomerContext);

  const ticket = allTickets[index];

  const dateOpened = ticket && format(new Date(ticket.createdAt), 'MM/dd/yy');

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <Modal
      isOpen={showModal}
      ariaHideApp={false}
      className="ticket__modal"
      overlayClassName="contact__modal__overlay"
    >
      <div className="ticket_modal__header">
        <div className="ticket_modal__headline__wrapper">
          <p className="ticket_modal__headline">Ticket Information</p>
        </div>
        <CloseButton onClick={closeModal} />
      </div>
      {ticket && (
        <div className="ticket__modal__inner__wrapper">
          <div className="ticket__modal__info__wrapper">
            <p className="info__label">Ticket ID:</p>
            <p className="info__value">{ticket.id}</p>
          </div>
          <div className="ticket__modal__info__wrapper">
            <p className="info__label">Status:</p>
            <p className="info__value">{TicketStatus[ticket.status]}</p>
          </div>
          <div className="ticket__modal__info__wrapper">
            <p className="info__label">Action:</p>
            <p className="info__value_action">{ticket.conciergeNote}</p>
          </div>
          <div className="ticket__modal__info__wrapper">
            <p className="info__label">Date opened:</p>
            <p className="info__value">{dateOpened}</p>
          </div>
          <div className="ticket__modal__info__wrapper">
            <p className="info__label">Customers note:</p>
            <p className="info__value">{ticket.customerNote}</p>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default TicketModal;
