import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
// import { StripeCardCvcElement, loadStripe, Stripe } from '@stripe/stripe-js';
import React, { FunctionComponent, useState, useEffect } from 'react';

import './CreditCardModal.scss';
import Modal from 'react-modal';

import { stripeKey } from '../../../config';

// import removeButton from '../../../assets/removeButton.png';
import { CreditCardForm, CreditCardFront, CloseButton } from '../../index';

import { CreditCard } from '../../../interfaces';

interface CreditCardModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCreditCard?: React.Dispatch<React.SetStateAction<CreditCard>>;
}

const CreditCardModal: FunctionComponent<CreditCardModalProps> = ({
  showModal,
  setShowModal,
  setCreditCard,
}) => {
  // const [cardType, setCardType] = useState('');
  const [stripe, setStripe] = useState<Stripe | null>(null);

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    loadStripe(stripeKey).then((value) => {
      setStripe(value);
    });
  }, []);

  return (
    <Modal
      isOpen={showModal}
      ariaHideApp={false}
      className="credit__card__modal"
      overlayClassName="modal__overlay"
    >
      <div className="modal__header">
        <div className="modal__header__text__wrapper">
          <p className="modal__header__text">Add Card</p>
        </div>
        <div className="close__button__wrapper">
          <CloseButton onClick={closeModal} />
        </div>
      </div>

      <div className="inner__wrapper">
        <p className="modal__text">
          Start typing to add your credit card details.
          <br />
          Everything will upadate according to your data.
        </p>
        <div className="credit__card__front__wrapper">
          <CreditCardFront />
        </div>

        <div className="form__wrapper">
          <Elements stripe={stripe}>
            <CreditCardForm
              setShowModal={setShowModal}
              setCreditCard={setCreditCard}
            />
          </Elements>
        </div>
      </div>
    </Modal>
  );
};

export default CreditCardModal;
