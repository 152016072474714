import React, { useState, useContext, useEffect } from 'react';
import {
  SubscriptionContext,
  CustomerContext,
  GlobalStateContext,
} from '../../../contexts';

import {
  FrequencySwitch,
  SubscriptionMainCard,
  MainHeader,
} from '../../../components';

import { SelectedSubscriptionMain } from '../../../interfaces';

import { DashboardTextEnum } from '../../../util/enums';

const SubscriptionPlanPage = () => {
  const { customerInfo, getCustomerDataFromToken } =
    useContext(CustomerContext);
  const { subscriptions, upgradeCustomerSubscription, toggleAutoRenew } =
    useContext(SubscriptionContext);
  const { setShowActivityIndicatorModal } = useContext(GlobalStateContext);

  const selectedSubscriptionId =
    customerInfo!.subscription.subscriptionAttributes.id;
  const selectedSubscriptionPeriod = customerInfo!.subscription.paymentType;
  const initialPositionSwitch = customerInfo!.subscription.autoRenew;

  const [isAutoRenewOff, setIsAutoRenewOff] = useState(initialPositionSwitch);
  const [selectedSubscription, setSelectedSubscription] =
    useState<SelectedSubscriptionMain>({
      id: selectedSubscriptionId,
      monthly: selectedSubscriptionPeriod === 1 ? true : false,
      year: selectedSubscriptionPeriod === 1 ? false : true,
    });

  const setAutoRenew = () => {
    setShowActivityIndicatorModal(true);
    toggleAutoRenew()
      .then(getCustomerDataFromToken)
      .then(() => setShowActivityIndicatorModal(false));
  };

  const upgradeSubscription = () => {
    setShowActivityIndicatorModal(true);
    upgradeCustomerSubscription(selectedSubscription)
      .then(getCustomerDataFromToken)
      .then(() => setShowActivityIndicatorModal(false));
  };

  useEffect(() => {
    setIsAutoRenewOff(customerInfo!.subscription.autoRenew);
  }, [customerInfo]);

  return (
    <div className="subscription_wrapper">
      <MainHeader title={DashboardTextEnum.SUBSCRIPTION_PLAN} />
      <div className="subsciption__plan__page__wrapper">
        <div className="frequency__switch__wrapper">
          <p className="frequency__switch__text">Auto re-new subscription</p>
          <FrequencySwitch
            textLeft="On"
            textRight="Off"
            isRightClicked={!isAutoRenewOff}
            setIsRightClicked={setIsAutoRenewOff}
            apiCall={setAutoRenew}
          />
        </div>
        <div className="subsciption__plan__page__inner__wrapper">
          {subscriptions.length > 0
            ? subscriptions.sort((a, b) => a.yearlyPrice - b.yearlyPrice)
            .map((subscription, index) => (
                <SubscriptionMainCard
                  subscriptionType={subscription}
                  selectedSubscription={selectedSubscription}
                  setSelectedSubscription={setSelectedSubscription}
                  key={index}
                  index={index}
                  numberOfSubscriptions={subscriptions.length}
                  upgradeSubscription={upgradeSubscription}
                />
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlanPage;
