import React, { useState, useEffect, FC } from 'react';

import moment from 'moment';

import {
  ProfileInput,
  AuthNameInput,
  AuthNoteInput,
} from '../../../components';

import {
  UserAtributes,
  Email,
  Phone,
  Address,
  FormatedContact,
  MyUserAttributesFormatedContact,
  EmailFormatedContact,
  PostalAddress,
} from '../../../interfaces';

import { ImportantDate, Url } from '../../../interfaces/ContactsInterface';

interface EditContactProps {
  contact: FormatedContact;
  editData: MyUserAttributesFormatedContact;
  setEditData: React.Dispatch<
    React.SetStateAction<MyUserAttributesFormatedContact>
  >;
  numberError: boolean;

  companies: string[];
  setCompanies: React.Dispatch<React.SetStateAction<string[]>>;
  profilePictureUri: string;
  setNumberError: React.Dispatch<React.SetStateAction<boolean>>;
  setProfilePictureUri: React.Dispatch<React.SetStateAction<string>>;
  editContactData: FormatedContact;
  setEditContactData: React.Dispatch<React.SetStateAction<FormatedContact>>;
}

const EditContact: FC<EditContactProps> = ({
  contact,
  editData,
  numberError,
  setNumberError,
  setEditData,
  companies,
  setCompanies,
  profilePictureUri,
  setProfilePictureUri,
  editContactData,
  setEditContactData,
}) => {
  const [newPhone, setNewPhone] = useState<string>('');
  const [newEmail, setNewEmail] = useState<string>('');
  const [newCompany, setNewCompany] = useState<string>('');
  const [newAddress, setNewAddress] = useState<string>('');
  const [newImAddress, setNewImAddress] = useState<string>('');
  const [newUrl, setNewUrl] = useState<string>('');
  const [newImportantDate, setNewImportantDate] = useState<string>('');
  // const [numberError, setNumberError] = useState(false);

  const regexPhoneNumber = /^[^a-zA-Z]*$/;

  const checkNumberValidity = (value: string) => {
    if (value && regexPhoneNumber.test(String(value)) === false)
      setNumberError(true);
    else {
      return setNumberError(false);
    }
  };

  const onAddPhoneClick = () => {
    if (numberError) return;
    const formatedPhone: Phone = {
      number: newPhone,
      isPrimary: false,
      userId: editData.id,
    };

    setEditData({
      ...editData,
      phones: [...editData?.phones, formatedPhone],
    });
  };

  const onRemovePhoneCLick = (index: number) => {
    const filteredPhones = editData.phones.filter((phone, i) => {
      return i !== index;
    });

    setEditData({
      ...editData,
      phones: filteredPhones,
    });
  };

  const onAddEmailClick = () => {
    const formatedEmail: EmailFormatedContact = {
      value: newEmail,
      userId: editData.id,
      label: '',
      isFromFacebook: false,
      isFromGoogle: false,
      isFromApple: false,
    };

    setEditData({
      ...editData,
      emails: [...editData.emails, formatedEmail],
    });
  };

  const onAddAddressClick = () => {
    const formatedAddress: PostalAddress = {
      city: '',
      country: '',
      state: '',
      street: newAddress,
      zip: '',
    };
    setEditData({
      ...editData,
      myAddresses: editData.myAddresses
        ? [...editData.myAddresses, formatedAddress]
        : [formatedAddress],
    });
  };

  const onAddUrlClick = () => {
    const formatedUrl: Url = {
      value: newUrl,
    };

    setEditContactData({
      ...editContactData,
      myUrls: editContactData.myUrls
        ? [...editContactData.myUrls, formatedUrl]
        : [formatedUrl],
    });
  };

  const onAddImAddressClick = () => {
    const formatedImAddress = {
      username: newImAddress,
      service: '',
    };

    setEditContactData({
      ...editContactData,
      myImAddresses: editContactData.myImAddresses
        ? [...editContactData.myImAddresses, formatedImAddress]
        : [formatedImAddress],
    });
  };

  const onAddImportantDateClick = () => {
    const formatedDate: ImportantDate = {
      value: parseStringToDate(newImportantDate),
    };

    setEditContactData({
      ...editContactData,
      myImportantDates: editContactData.myImAddresses
        ? [...editContactData.myImportantDates, formatedDate]
        : [formatedDate],
    });
  };

  const onRemoveEmailClick = (index: number) => {
    const filteredEmails = editData.emails.filter((email, i) => {
      return i !== index;
    });

    setEditData({
      ...editData,
      emails: filteredEmails,
    });
  };

  const onRemoveImAddressClick = (index: number) => {
    const filteredAddresses = editContactData.myImAddresses?.filter(
      (address, i) => {
        return i !== index;
      }
    );

    setEditContactData({
      ...editContactData,
      myImAddresses: filteredAddresses,
    });
  };

  const onRemoveImportantDateClick = (index: number) => {
    const filteredImportantDates = editContactData.myImportantDates?.filter(
      (address, i) => {
        return i !== index;
      }
    );

    setEditContactData({
      ...editContactData,
      myImportantDates: filteredImportantDates,
    });
  };

  const onRemoveAddressClick = (index: number) => {
    const filteredAddresses = editData.myAddresses?.filter((address, i) => {
      return i !== index;
    });

    setEditData({
      ...editData,
      myAddresses: filteredAddresses,
    });
  };

  const onRemoveUrlClick = (index: number) => {
    console.log(index);
    const filteredUrls = editContactData.myUrls?.filter((url, i) => {
      return i !== index;
    });

    setEditContactData({
      ...editContactData,
      myUrls: filteredUrls,
    });
  };

  const onAddCompanyClick = () => {
    setCompanies([...companies, newCompany]);
  };

  const onRemoveCompanyClick = (index: number) => {
    const filteredCompanies = companies.filter((company, i) => {
      return i !== index;
    });

    setCompanies(filteredCompanies);
  };

  const updateContactName = (
    fieldName: keyof UserAtributes,
    element: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (editData) {
      setEditData({
        ...editData,
        [fieldName]: element.target.value,
      });
    }
  };

  const updateContactAttributes = (
    fieldName: keyof FormatedContact,
    element: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (editContactData) {
      setEditContactData({
        ...editContactData,
        [fieldName]: element.target.value,
      });
    }

    console.log(fieldName, element.target.value);
  };

  const updateContactImAdress = (
    fieldName: string,
    value: string,
    index: number
  ) => {
    setEditContactData({
      ...editContactData,
      myImAddresses: editContactData.myImAddresses?.map(
        (imAddress, innerIndex) => {
          if (index === innerIndex) return { ...imAddress, [fieldName]: value };
          return imAddress;
        }
      ),
    });
  };

  const updateContactAdress = (
    fieldName: keyof Address,
    value: string,
    index: number
  ) => {
    if (editData) {
      setEditData({
        ...editData,
        myAddresses: editData.myAddresses?.map((address, innerIndex) => {
          if (innerIndex === index) return { ...address, [fieldName]: value };
          return address;
        }),
      });
    }
  };

  const parseStringToDate = (value: string): Date => {
    let parsedValue: Date = new Date();
    try {
      parsedValue = new Date(value);
    } catch (error) {
      console.log(`Invalid input for date: ${error}`);
    }
    return parsedValue;
  };

  const updateContactImportantDate = (
    fieldName: keyof ImportantDate,
    value: string,
    index: number
  ) => {
    const parsedValue = parseStringToDate(value);
    setEditContactData({
      ...editContactData,
      myImportantDates: editContactData.myImportantDates?.map(
        (myImportantDate, innerIndex) => {
          if (index === innerIndex)
            return { ...myImportantDate, [fieldName]: parsedValue };
          return myImportantDate;
        }
      ),
    });
  };

  const updateContactUrl = (
    fieldName: keyof Url,
    value: string,
    index: number
  ) => {
    setEditContactData({
      ...editContactData,
      myUrls: editContactData.myUrls?.map((myUrl, innerIndex) => {
        if (index === innerIndex) return { ...myUrl, [fieldName]: value };
        return myUrl;
      }),
    });
  };

  const updateUserPhone = (value: string, index: number) => {
    checkNumberValidity(value);

    const phones = editData.phones.slice();
    phones[index].number = value;

    return setEditData({
      ...editData,
      phones,
    });
  };

  const updateUserEmail = (value: string, index: number) => {
    const emails = editData.emails.slice();
    emails[index].value = value;

    return setEditData({
      ...editData,
      emails,
    });
  };

  const updateUserCompany = (value: string, index: number) => {
    const newCompanies = companies.slice();
    newCompanies[index] = value;

    return setCompanies(newCompanies);
  };

  const renderBasicInputs = () => {
    return (
      <div style={{ width: '100%' }}>
        <AuthNameInput
          label="Prefix"
          value={editContactData.prefix ? editContactData.prefix : ''}
          onChange={(e) => {
            updateContactAttributes('prefix', e);
          }}
        />

        <AuthNameInput
          label="First Name"
          value={editData.firstName}
          onChange={(e) => {
            updateContactName('firstName', e);
          }}
        />
        <AuthNameInput
          label="Middle Name"
          value={
            editContactData.phoneticMiddleName
              ? editContactData.phoneticMiddleName
              : ''
          }
          onChange={(e) => {
            updateContactAttributes('phoneticMiddleName', e);
          }}
        />
        <AuthNameInput
          label="Last Name"
          value={editData.lastName}
          onChange={(e) => {
            updateContactName('lastName', e);
          }}
        />

        <AuthNameInput
          label="Sufix"
          value={editContactData.sufix ? editContactData.sufix : ''}
          onChange={(e) => {
            updateContactAttributes('sufix', e);
          }}
        />
      </div>
    );
  };

  const renderCompanies = () => {
    if (companies?.length) {
      return companies
        ?.filter((company) => company)
        .map((company, i) => (
          <ProfileInput
            key={i}
            index={i}
            labelName="Company"
            buttonType="delete"
            onRemoveClick={onRemoveCompanyClick}
            setParentData={(value) => updateUserCompany(value, i)}
            data={company}
          />
        ));
    }
  };
  const renderImAddresses = () => {
    const res = Array.from(
      editContactData?.myImAddresses ? editContactData.myImAddresses : []
    ).map((myImAddress, index) => {
      return (
        <div>
          <ProfileInput
            labelName="Username"
            buttonType="delete"
            onRemoveClick={() => onRemoveImAddressClick(index)}
            setParentData={(value) =>
              updateContactImAdress('username', value, index)
            }
            data={myImAddress.username}
          />
          <ProfileInput
            labelName="Service"
            setParentData={(value) =>
              updateContactImAdress('service', value, index)
            }
            data={myImAddress.service}
          />
        </div>
      );
    });

    return res;
  };

  const renderURLS = () => {
    const res = Array.from(
      editContactData?.myUrls ? editContactData.myUrls : []
    ).map((myURL, index) => {
      return (
        <ProfileInput
          key={index}
          labelName="Value"
          onRemoveClick={() => onRemoveUrlClick(index)}
          buttonType="delete"
          setParentData={(value) => updateContactUrl('value', value, index)}
          data={myURL.value}
        />
      );
    });

    return res;
  };

  const renderAddresses = () => {
    return Array.from(editData.myAddresses ? editData.myAddresses : []).map(
      (myAddress, index) => {
        return (
          <div key={index} style={{ marginTop: '20px' }}>
            <ProfileInput
              labelName="Address"
              buttonType="delete"
              onRemoveClick={() => onRemoveAddressClick(index)}
              setParentData={(value) =>
                updateContactAdress('street', value, index)
              }
              data={myAddress.street}
            />
            <ProfileInput
              labelName="City"
              setParentData={(value) =>
                updateContactAdress('city', value, index)
              }
              data={myAddress.city}
            />

            <ProfileInput
              labelName="State"
              setParentData={(value) =>
                updateContactAdress('state', value, index)
              }
              data={myAddress.state}
            />

            <ProfileInput
              labelName="Zip"
              setParentData={(value) =>
                updateContactAdress('zip', value, index)
              }
              data={myAddress.zip}
            />

            <ProfileInput
              labelName="Country"
              setParentData={(value) =>
                updateContactAdress('country', value, index)
              }
              data={myAddress.country}
            />
          </div>
        );
      }
    );
  };

  const renderImportantDates = () => {
    return Array.from(
      editContactData.myImportantDates ? editContactData.myImportantDates : []
    ).map((myDate, index) => {
      return (
        <ProfileInput
          key={index}
          buttonType="delete"
          labelName="Date"
          onRemoveClick={onRemoveImportantDateClick}
          setParentData={(value) =>
            updateContactImportantDate('value', value, index)
          }
          data={moment(myDate.value).format('MMM Do YY')}
        />
      );
    });
  };

  useEffect(() => {
    if (contact?.myUserAttributes) setEditData(contact.myUserAttributes);

    const myCompanies = contact.myCompanies.map((company) => {
      return company.name;
    });

    if (contact.myCompanies) setCompanies(myCompanies);
    setEditContactData(contact);
  }, [contact]);

  useEffect(() => {
    console.log(numberError);
  }, [numberError]);

  return (
    <div className="edit_contacts_wrapper">
      <div className="edit_contacts_inner_wrapper">
        {/* <div className="photo_circle">
          <p>
            Add
            <br />
            photo
          </p>
        </div> */}
        <div className="basic_inputs_wrapper">
          {renderBasicInputs()}
          <div className="notes_wrapper">
            <AuthNoteInput
              label="Notes"
              value={editContactData.notes ? editContactData.notes : ''}
              onChange={(e: any) => {
                updateContactAttributes('notes', e);
              }}
            />
          </div>
          {editData?.emails?.length > 0 &&
            editData?.emails.map((email, i) => (
              <ProfileInput
                key={i}
                index={i}
                labelName="Email"
                buttonType="delete"
                onRemoveClick={onRemoveEmailClick}
                setParentData={(value) => updateUserEmail(value, i)}
                data={email.value}
              />
            ))}
          <ProfileInput
            labelName="Add Email"
            buttonType="add"
            onAddClick={onAddEmailClick}
            setParentData={setNewEmail}
            data={newEmail}
          />
          <div style={{ marginTop: '20px' }}>{renderCompanies()}</div>
          <ProfileInput
            labelName="Company"
            buttonType="add"
            onAddClick={onAddCompanyClick}
            setParentData={setNewCompany}
            data={newCompany}
          />
          <div style={{ marginTop: '20px' }}>
            {editData?.phones?.length > 0 &&
              editData?.phones?.map((phoneNumber, i) => (
                <ProfileInput
                  key={i}
                  index={i}
                  labelName="Phone"
                  buttonType="delete"
                  onRemoveClick={onRemovePhoneCLick}
                  setParentData={(value) => updateUserPhone(value, i)}
                  data={phoneNumber.number}
                />
              ))}
          </div>
          <ProfileInput
            labelName="Add Phone"
            buttonType="add"
            onAddClick={onAddPhoneClick}
            setParentData={(value) => {
              checkNumberValidity(value);
              setNewPhone(value);
            }}
            data={newPhone}
          />

          {renderAddresses()}
          <ProfileInput
            labelName="Add Address"
            buttonType="add"
            isLabelLong
            onAddClick={onAddAddressClick}
            setParentData={setNewAddress}
            data={newAddress}
          />
          <div style={{ marginTop: '20px' }}>
            {renderImAddresses()}
            <ProfileInput
              labelName="Add ImAddress"
              buttonType="add"
              isLabelLong
              onAddClick={onAddImAddressClick}
              setParentData={setNewImAddress}
              data={newImAddress}
            />
          </div>
          {renderURLS()}
          <ProfileInput
            labelName="Add URL"
            buttonType="add"
            isLabelLong
            onAddClick={onAddUrlClick}
            setParentData={setNewUrl}
            data={newUrl}
          />

          {renderImportantDates()}
          <ProfileInput
            labelName="Add Important Date"
            buttonType="add"
            isLabelLong
            onAddClick={onAddImportantDateClick}
            setParentData={setNewImportantDate}
            isDate={true}
            data={newImportantDate}
          />
        </div>
      </div>
    </div>
  );
};

export default EditContact;
