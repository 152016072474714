import React, {
  createContext,
  useState,
  FunctionComponent,
  useContext,
} from 'react';
import axios, { AxiosInstance } from 'axios';

import { AxiosContext } from './index';

import {
  FormatedContact,
  MyUserAttributesFormatedContact,
} from '../interfaces';
import { Url } from '../interfaces/ContactsInterface';

interface ContactsContextProviderProps {
  children: React.ReactNode;
}

interface ContactsContextState {
  contactsFromCloud: FormatedContact[];
  editContact: (
    contact: FormatedContact,
    profilePictureUri: string
  ) => Promise<any>;
  getAllContactsFromCloud: () => Promise<void>;
  addToPiorityList: (contacts: FormatedContact[]) => Promise<any>;
  removeFromPiorityList: (contacts: FormatedContact[]) => Promise<void>;
  toogleIsDeletedForContactOnCloud: (contactId: number) => Promise<void>;
}

const ContactsContext = createContext({} as ContactsContextState);

const ContactsContextProvider: FunctionComponent<ContactsContextProviderProps> =
  ({ children }) => {
    const { axiosInstance } = useContext(AxiosContext);

    const [contactsFromCloud, setContactsFromCloud] = useState<
      FormatedContact[]
    >([] as FormatedContact[]);

    const getAllContactsFromCloud = async () => {
      return axiosInstance
        .get('/contact/getAll')
        .then((response) => {
          return response.data;
        })
        .then((link) =>
          axios.get(link).then((response) => {
            setContactsFromCloud(response.data);
          })
        )
        .catch((error) => {
          console.log(error);
          console.log('Error getting contacts from the cloud: ', error);
        });
    };

    // fetch(link, {
    //   method: 'GET',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    //     Host: 's3.amazonaws.com',
    //   },
    // })

    const addToPiorityList = async (contacts: FormatedContact[]) => {
      const ids = contacts.map((contact) => contact.id);

      return axiosInstance
        .put('/priorityList/add', { contactsIds: ids })
        .then((response) => {
          console.log(response.status);
          return response.status;
        })
        .catch((error) =>
          console.log('ADDING TO PRIORITY LIST ERROR: ', error.response.data)
        );
    };

    const editContact = async (
      contact: FormatedContact,
      profilePictureUri: string
    ) => {
      if (contact.myUserAttributes && contact.myUserAttributes.myAddresses) {
        contact.myUserAttributes.myAddresses =
          contact.myUserAttributes.myAddresses.map((myAddress) => {
            return {
              ...myAddress,
              label: myAddress.label === null ? undefined : myAddress.label,
            };
          });
      }

      const dataToBeUpdated = {
        ...contact,

        photo:
          profilePictureUri.slice(0, 4) === 'data'
            ? profilePictureUri
            : undefined,
      };
      dataToBeUpdated.rawContactId =
        dataToBeUpdated.rawContactId === null
          ? undefined
          : dataToBeUpdated.rawContactId;
      dataToBeUpdated.recordId =
        dataToBeUpdated.recordId === null
          ? undefined
          : dataToBeUpdated.recordId;

      console.log('DATA TO BE UPDATED', JSON.stringify(dataToBeUpdated));

      return axiosInstance
        .put('/contact/update', dataToBeUpdated)
        .then((response) =>
          console.log('UPDATE CONTACT RESPONSE', response.data)
        )
        .catch((error) => {
          console.log(`UPDATE CONTACT ERROR: ${error}`);
        });
    };

    const removeFromPiorityList = async (contacts: FormatedContact[]) => {
      const ids = contacts.map((contact) => contact.id);
      console.log('IDS TO DELETE: ', ids);
      return axiosInstance
        .put('./priorityList/removeContacts', { contactsIds: ids })
        .then((response) => console.log(response.data))
        .catch((error) =>
          console.log('DELETING TO PRIORITY LIST ERROR: ', error.response.data)
        );
    };

    const toogleIsDeletedForContactOnCloud = async (contactId: number) => {
      return axiosInstance
        .put(`/contact/toogleIsDeleted/${contactId}`)
        .then((response) => console.log(response.data))
        .catch((error) =>
          console.log('ERROR IN DELETING CONTACT: ', error.response.data)
        );
    };

    const providerValue = {
      contactsFromCloud,
      editContact,
      getAllContactsFromCloud,
      addToPiorityList,
      removeFromPiorityList,
      toogleIsDeletedForContactOnCloud,
    };

    return (
      <ContactsContext.Provider value={providerValue}>
        {children}
      </ContactsContext.Provider>
    );
  };

export { ContactsContext, ContactsContextProvider };
